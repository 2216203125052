import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { Image } from '../entities/image.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'

type State = {
    images: AsyncValue<Image[]>,
}

type Actions = {
    getImages: () => Promise<void>,
    createImage: (
        file: File,
    ) => Promise<void>,
    deleteImage: (
        id: string,
    ) => Promise<void>,
    retry: () => Promise<void>,
}

export const imageManagementStore = create<State & Actions>((set) => ({
    images: new AsyncLoading<Image[]>(),
    getImages: async () => {
        try {
            var result: Image[] = await apiClient.getImages();

            set(
                () => (
                    { images: new AsyncData<Image[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { images: new AsyncError<Image[]>(e) }
                )
            );
        }
    },
    createImage: async (file: File) => {
        try {
            var result: Image = await apiClient.uploadImage(file);
            set(
                (state) => (
                    { images: new AsyncData([...(state.images as AsyncData<Image[]>).value, result]) }
                )
            );
        } catch (e) {
            throw e;
        }
    },
    deleteImage: async (id: string) => {
        try {
            await apiClient.deleteImage(
                id,
            );
    
            set(
                (state: State) => (
                    { images: new AsyncData((state.images as AsyncData<Image[]>).value.filter(item => item._id !== id)) }
                )
            );
        } catch (e) {
            alert(e)
        }
    },
    retry: async () => {
        set(
            () => (
                { images: new AsyncLoading() }
            )
        );

        try {
            var result: Image[] = await apiClient.getImages();

            set(
                () => (
                    { images: new AsyncData<Image[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { images: new AsyncError<Image[]>(e) }
                )
            );
        }
    },
}))