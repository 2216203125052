import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { Product } from '../entities/product.ts'
import { SearchParams } from '../entities/search_params.ts';

type State = {
    products: AsyncValue<Product[]>,
}

type Actions = {
    load: (params: SearchParams) => Promise<void>,
    retry: (paramms: SearchParams) => Promise<void>,
}

export const searchStore = create<State & Actions>((set, state) => ({
    products: new AsyncLoading<Product[]>(),
    params: {},
    load: async (params: SearchParams) => {
        set(
            () => (
                {
                    products: new AsyncLoading<Product[]>()
                }
            )
        )
        try {
            const result: Product[] = await apiClient.getProducts(params);

            set(
                () => (
                    { products: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        products: new AsyncError(e)
                    }
                )
            );
        }
    },
    retry: async (params: SearchParams) => {
        set(
            () => (
                { products: new AsyncLoading() }
            )
        );

        try {
            const result: Product[] = await apiClient.getProducts(params);

            set(
                () => (
                    { products: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        products: new AsyncError(e)
                    }
                )
            );
        }
    },
}))