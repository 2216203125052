import React from "react";
import { Product } from "../../data/entities/product";
import { Grid2 } from "@mui/material";
import { ProductCard } from "../molecules/product_card.tsx";

type ProductsGridProps = {
    products: Product[],
    marginOverride?: string,
}

export const ProductsGrid: React.FC<ProductsGridProps> = (
    props: ProductsGridProps,
) => {
    const {
        products,
        marginOverride,
    } = props;

    return <Grid2
        container
        spacing={{
            xs: "16px",
            sm: "16px",
            md: "24px",
            lg: "40px",
            xl: "40px",
        }}
        columns={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
        }}
        margin={
            marginOverride ??
            {
                xs: "0px 48px",
                sm: "0px 48px",
                md: "0px 96px",
                lg: "0px 124px",
                xl: "0px 200px",
            }
        }
    >
        {products.map((el) => (
            <Grid2
                key={el._id}
                size={1}
            >
                <ProductCard
                    product={el}
                />
            </Grid2>
        ))}
    </Grid2>;
}