import { Button, DialogActions } from "@mui/material";
import React from "react";

type DialogActionButtonsProps = {
    primaryLabel?: string,
    primaryIsDisabled?: boolean,
    onPrimaryClick: () => void,
    secondaryLabel?: string,
    secondaryIsDisabled?: boolean,
    onSecondaryClick: () => void,
}

export const DialogActionButtons: React.FC<DialogActionButtonsProps> = (props: DialogActionButtonsProps) => {
    const {
        primaryLabel,
        primaryIsDisabled,
        onPrimaryClick,
        secondaryLabel,
        secondaryIsDisabled,
        onSecondaryClick,
    } = props;

    return <DialogActions>
        <Button
            onClick={onSecondaryClick}
            variant="outlined"
            disabled={secondaryIsDisabled}
        >
            {secondaryLabel ?? "Cancel"}
        </Button>
        <Button
            onClick={onPrimaryClick}
            variant="outlined"
            disabled={primaryIsDisabled}
        >
            {primaryLabel ?? "Submit"}
        </Button>
    </DialogActions>
}