import React, { useState } from 'react';
import { categoryManagementStore } from '../../data/states/category_management_store.ts';
import { Category } from '../../data/entities/category.ts';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Add } from "@mui/icons-material";
import { FloatingActionButton } from '../../components/atoms/floating_action_button.tsx';
import { Align, Alignment } from '../../components/atoms/align.tsx';
import { LocalisedCanonicalList } from './components/localised_canonical_list.tsx';
import { CreateProductFacetModal } from './components/create_product_facet_model.tsx';

export const CategoryTab: React.FC = () => {
    const { categories, createCategory, deleteCategory, retry } = categoryManagementStore(
        (state) => state,
    );
    const [modalIsVisible, setModalIsVisible] = useState(false);

    return categories.when(
        (data: Category[]) => {
            return <>
                <Align
                    alignment={Alignment.top}
                >
                    <LocalisedCanonicalList
                        title="Categories"
                        items={data}
                        deleteItem={async (category) => {
                            await deleteCategory(category._id);
                        }}
                    />
                </Align>
                <CreateProductFacetModal
                    facetName='Category'
                    visible={modalIsVisible}
                    onSubmit={(value: string) => {
                        createCategory(value).then(
                            (v) => {
                                setModalIsVisible(false);
                            }
                        ).catch(
                            (e) => {
                                alert(e);
                            }
                        );
                    }}
                    onClose={() => {
                        setModalIsVisible(false);
                    }}
                />
                <FloatingActionButton
                    onClick={() => {
                        setModalIsVisible(true);
                    }}
                >
                    <Add />
                </FloatingActionButton>
            </>;
        },
        (error: Error) => {
            return <Align
                alignment={Alignment.center}
            >
                <div>
                    <Typography
                        variant='h5'
                        sx={{
                            pb: "16px"
                        }}
                    >
                        Error fetching categories
                    </Typography>
                    <Align
                        alignment={Alignment.center}
                    >
                        <Button
                            variant='contained'
                            onClick={retry}
                            sx={{
                                width: "120px"
                            }}
                        >
                            RETRY
                        </Button>
                    </Align>
                </div>
            </Align >
        },
        () => {
            return <Align
                alignment={Alignment.center}
            >
                <CircularProgress />
            </Align>;
        },
    );
};