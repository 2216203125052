import React, { PropsWithChildren } from "react";

type ImageContainerProps = PropsWithChildren & {
    src: string,
    fit: 'cover' | 'contain',
    width?: number,
    height?: number,
    borderRadius?: number,
    onClick?: () => void,
    border?: string,
}

export const ImageContainer: React.FC<ImageContainerProps> = (props: ImageContainerProps) => {
    const {
        src,
        children,
        fit,
        width,
        height,
        borderRadius,
        onClick,
        border,
    } = props;

    return <div
        onClick={onClick}
        style={{
            display: "grid",
            width: width,
            height: height,
            justifyContent:"end"
        }}
    >
        <img
            src={src}
            alt=""
            style={{
                gridRow: 1,
                gridColumn: 1,
                objectFit: fit,
                width: width ?? "100%",
                height: height,
                border: border,
                borderRadius: borderRadius ?? 8,
                alignSelf: "end"
            }}
        />
        <div
            style={{
                gridRow: 1,
                gridColumn: 1,
                position: "relative",
            }}
        >
            {children}
        </div>
    </div >
}