import { DialogContent, TextField, DialogActions, DialogTitle, Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import {Validators} from '../../../utils/validators.ts';

type CreateProductFacetModalProps = {
    facetName: string,
    onSubmit: (value: string) => void;
    onClose: () => void,
    visible: boolean,
}

export const CreateProductFacetModal: React.FC<CreateProductFacetModalProps> = (props: CreateProductFacetModalProps) => {
    const [value, setValue] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(true);

    const {
        facetName,
        onSubmit,
        onClose,
        visible,
    } = props;

    return <Dialog
        open={visible}
        onClose={onClose}
    >
        <DialogContent>
            <DialogTitle>
                {"Create " + facetName}
            </DialogTitle>
            <TextField
                label="Name"
                type="text"
                onChange={(v) => {
                    setIsValid(Validators.isOnlyAlphabeticAndSpaces(v.target.value));
                    setValue(v.target.value);
                }}
                error={!isValid}
                helperText={
                    isValid ? null : "Please enter a valid name"
                }
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onClose}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button
                disabled={!value || !isValid}
                onClick={() => {
                    // Safe to bang, check done for button's disabled boolean
                    onSubmit(value!);
                }}
                variant="contained"
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>;
}