import { DialogContent, DialogActions, DialogTitle, Button, Dialog, Typography, Box } from "@mui/material";
import React from "react";
import { apiClient } from "../../../data/network/api_client.ts";
import { Product } from "../../../data/entities/product.ts";
import { ImageContainer } from "../../../components/atoms/image_container.tsx";
import { Spacer } from "../../../components/atoms/spacer.tsx";
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { Image  } from "@mui/icons-material";

type DeleteProductConfirmationModalProps = {
    product: Product,
    onConfirm: () => void;
    onCancel: () => void,
    visible: boolean,
}

export const DeleteProductConfirmationModal: React.FC<DeleteProductConfirmationModalProps> = (props: DeleteProductConfirmationModalProps) => {
    const {
        product,
        onConfirm,
        onCancel,
        visible,
    } = props;

    const img: string | undefined = product.images.length ? apiClient.makeImageUrl(product.images[0].path) : undefined;

    return <Dialog
        open={visible}
        onClose={onCancel}
    >
        <DialogTitle>
            Confirm
        </DialogTitle>
        <DialogContent>
            <Typography
                variant="body1"
                align="center"
                sx={{
                    margin: "0px 32px"
                }}
            >
                You are about to delete
            </Typography>
            <Spacer
                axis="vertical"
                pixels={16}
            />
            {
                img ?
                    <ImageContainer
                        src={img}
                        fit="contain"
                        height={200}
                        width={300}
                    /> :
                    <Box
                        sx={{
                            height: 200,
                            width: 300,
                            borderRadius: '8px',
                            border: "1px solid",
                            borderColor: 'divider',
                        }}
                    >
                        <Align
                            alignment={Alignment.center}
                        >
                            <Image
                                sx={{
                                    width: 80,
                                    height: 80,
                                    color: "grey"
                                }}
                            />
                        </Align>
                    </Box>
            }
            <Spacer
                axis="vertical"
                pixels={16}
            />
            <Typography
                align="center"
            >
                {product.title}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onCancel}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button
                onClick={onConfirm}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}