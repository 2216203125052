import React from "react";

type SpacerProps = {
    axis: "horizontal" | "vertical",
    pixels: number,
};

export const Spacer: React.FC<SpacerProps> = (props: SpacerProps) => {
    const {
        axis,
        pixels,
    } = props;
    return <div
        style={{
            width: axis === "vertical" ? 0 : pixels,
            height: axis === "horizontal" ? 0 : pixels,
        }}
    />;
}