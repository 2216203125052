import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import { ThemeProvider } from '@emotion/react';
import { responsiveTheme } from './theme.ts';
import { Home } from './features/home/home.tsx';
import { Management } from './features/management/management.tsx';
import { AuthProvider } from './features/auth/auth_provider.tsx';
import { AuthedRoute } from './features/auth/authed_route.tsx';
import { Layout } from './components/organisms/layout.tsx';
import { responsiveAppTheme } from './app_theme.ts';
import { Search } from './features/search/search.tsx';
import { ProductDetails } from './features/product_details/product_details.tsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout>
      <Home />
    </Layout>,
  },
  {
    path: "/search",
    element: <Layout>
      <Search />
    </Layout>,
  },
  {
    path: "management",
    element: <AuthedRoute>
      <ThemeProvider
        theme={responsiveTheme}
      >
        <Management />
      </ThemeProvider>
    </AuthedRoute>
  },
  {
    path: "/product/:id",
    element: <Layout>
      <ProductDetails />
    </Layout>,
  },
  {
    path: "*",
    element: <h1>404</h1>,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <ThemeProvider
      theme={responsiveAppTheme}
    >
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
