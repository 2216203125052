import React from "react";
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import { productFeatureManagementStore } from "../../../data/states/product_feature_management_store.ts";

type FeatureProductsFilterProps = {
    selectedFeatureIds: string,
    onSelect: (selectedIds: string) => void,
}

export const FeatureProductsFilter: React.FC<FeatureProductsFilterProps> = (
    props: FeatureProductsFilterProps,
) => {
    const { productFeatures, retry } = productFeatureManagementStore();

    const {
        selectedFeatureIds,
        onSelect,
    } = props;

    return productFeatures.when(
        (data) => {
            return <FormControl>
                <InputLabel id="feature-selector-label">Feature</InputLabel>
                <Select
                    labelId="feature-selector-label"
                    value={selectedFeatureIds}
                    input={<OutlinedInput label="Features" />}
                    onChange={(v: SelectChangeEvent<string>) => {
                        onSelect(v.target.value)
                    }}
                    sx={{
                        width: 250
                    }}
                >
                    <MenuItem
                        key={'all'}
                        value={'all'}
                    >
                        All
                    </MenuItem>
                    {
                        data.map(
                            (item) => <MenuItem
                                key={item._id}
                                value={item._id}
                            >
                                {item.localisedValue}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        },
        (error) => {
            return <Button
                variant="outlined"
                onClick={retry}
            >
                RETRY PRODUCT FEATURE LOAD
            </Button>;
        },
        () => {
            return <Typography>Loading product features</Typography>
        },
    )
}