
type _AsyncValue<T> = T | Error | undefined;

class AsyncValue<T> {
    value: _AsyncValue<T>;
    constructor(_value: _AsyncValue<T>) {
        this.value = _value;

    }

    public when<R>(
        data: (data: T) => R,
        error: (error: Error) => R,
        loading: () => R
    ): R {
        if (this.value === undefined) {
            return loading();
        } else if (this.value instanceof Error) {
            return error(this.value as Error);
        } else {
            return data(this.value as T);
        }
    }
}

class AsyncData<T> extends AsyncValue<T> {
    value: T;
    constructor(_value: T) {
        super(_value);
        this.value = _value;
    }

    public get data(): T {
        return this.value;
    }
}

class AsyncError<T> extends AsyncValue<T> {
    value: Error;
    constructor(_value: Error) {
        super(_value);
        this.value = _value;
    }
}

class AsyncLoading<T> extends AsyncValue<T> {
    value: undefined;

    constructor() {
        super(undefined);
        this.value = undefined;
    }
    public get data(): undefined {
        return undefined;
    }
}

export {
    AsyncValue,
    AsyncData,
    AsyncError,
    AsyncLoading,
}