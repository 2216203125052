import React, { useEffect } from 'react';
import { searchStore } from '../../data/stores/search_store.tsx';
import { Box, Button, Grid2, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { SearchParams } from '../../data/entities/search_params.ts';
import { appColors, appTheme } from '../../app_theme.ts';
import { searchBuilder } from '../../data/stores/search_builder_store.tsx';
import { ProductsGrid } from '../../components/organisms/products_grid.tsx';

function getSearchTerm(params: SearchParams): string {
    if (params.keywords) {
        return params.keywords;
    } else {
        return params.category?.localisedValue ?? params.feature?.localisedValue ?? params.range?.localisedValue ?? "All products";
    }
}

export const Search: React.FC = () => {
    const { params } = searchBuilder();
    const { products, load } = searchStore();

    useEffect(
        () => {
            load(params);
        },
        [load, params, params.keywords, params.category, params.feature, params.range],
    );

    const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));

    return <>
        <Box
            sx={{
                backgroundColor: 'primary.light',
                minHeight: "132px",
                maxHeight: "303px",
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'primary.light',
                    height: {
                        xs: '132px',
                        sm: '132px',
                        md: '208px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center"
                }}
            >
                {
                    !isMobile && params.keywords
                        ? <Typography
                            align='center'
                            variant='h6'
                            color='primary.dark'
                        >
                            Showing search results for
                        </Typography>
                        : <></>
                }
                <Typography
                    align='center'
                    variant='h3'
                    color='primary'
                >
                    {getSearchTerm(params)}
                </Typography>
            </Box>
        </Box>
        {

            products.when(
                (products) => {
                    if (!products.length) {
                        return <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '90px 0px'
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{
                                    color: appColors.greys.medium
                                }}
                            >
                                No results
                            </Typography>
                        </div>
                    }
                    return <Box
                        paddingTop={{
                            xs: "48px",
                            sm: "48px",
                            md: "96px",
                            lg: "140px",
                            xl: "140px",
                        }}
                        paddingBottom={{
                            xs: "48px",
                            sm: "48px",
                            md: "96px",
                            lg: "140px",
                            xl: "140px",
                        }}
                    >
                        <ProductsGrid
                            products={products}
                        />
                    </Box>
                },
                (error) => {
                    return <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '90px 0px'
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                color: appColors.greys.medium
                            }}
                        >
                            Something went wrong, please try again.
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '40px'
                            }}
                        >
                            <Button
                                variant='filled'
                                onClick={() => {
                                    load(params);
                                }}
                            >
                                Retry
                            </Button>
                        </div>
                    </div>
                },
                () => {
                    return <Grid2
                        container
                        spacing={{
                            xs: 6,
                            md: 6,
                            lg: 10,
                            xl: 10,
                        }}
                        columns={{
                            xs: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                        }}
                        margin={{
                            xs: "48px 24px",
                            sm: "48px 48px",
                            md: "48px 62px",
                            lg: "48px 124px",
                            xl: "48px 200px",
                        }}
                    >
                        {Array.from(Array(16)).map((el, index) => (
                            <Grid2 key={index} size={1}>
                                <Skeleton
                                    sx={{
                                        margin: '0px',
                                        padding: "0px",
                                        height: {
                                            xs: 135,
                                            sm: 200,
                                            md: 270,
                                            lg: 300,
                                            xl: 338,
                                        },
                                    }}
                                />
                                <Skeleton
                                    height="32px"
                                />
                                <Skeleton
                                    height="20px"
                                />
                            </Grid2>
                        ))}
                    </Grid2>
                }
            )
        }
    </>
};