import { Box, Modal, Drawer, Typography, useMediaQuery, IconButton, Button, CircularProgress } from "@mui/material";
import React from "react"
import { appTheme } from "../../app_theme.ts";
import { Close } from "@mui/icons-material";
import { categoriesStore } from "../../data/stores/categories_store.tsx";
import { Category } from "../../data/entities/category.ts";
import { AsyncValue } from "../../data/entities/async_value.ts";
import { searchBuilder } from "../../data/stores/search_builder_store.tsx";
import { useNavigate } from "react-router-dom";

type AppBarMenuDrawerProps = {
    isOpen: boolean,
    onClose: () => void,
}

type ContentProps = {
    onClose: () => void,
    categories: AsyncValue<Category[]>,
    onRetry: () => void,
    onSelect: (category: Category) => void,
    onAllSelect: () => void,
}

const MobileContent: React.FC<ContentProps> = (
    props: ContentProps,
) => {
    const {
        onClose,
        categories,
        onRetry,
        onSelect,
        onAllSelect,
    } = props;

    return <Box
        sx={{
            backgroundColor: 'primary.light',
            padding: '24px'
        }}
    >
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Typography>
                Peachy Tea Store
            </Typography>
            <Close
                onClick={onClose}
            />
        </div>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: "68px 0px",
            }}
        >
            {
                categories.when(
                    (data) => {
                        return <>
                            <Button
                                key={'menu_item_all'}
                                onClick={() => {
                                    onClose();
                                    onAllSelect();
                                }}
                            >
                                <Typography
                                    align="center"
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                    variant="h5"
                                >
                                    All products
                                </Typography>
                            </Button>
                            {
                                data.map(
                                    (item) => <Button
                                        key={'menu_button_' + item.localisedValue}
                                        onClick={() => {
                                            onClose();
                                            onSelect(item);
                                        }}
                                    >
                                        <Typography
                                            align="center"
                                            variant="h5"
                                        >
                                            {item.localisedValue}
                                        </Typography>
                                    </Button>
                                )
                            }
                        </>
                    },
                    (error) => {
                        return <Button
                            variant="outlined"
                            onClick={onRetry}
                        >
                            RETRY
                        </Button>
                    },
                    () => {
                        return <div
                            style={{
                                display: 'flex',
                                justifyContent: "center"
                            }}
                        >
                            <CircularProgress />
                        </div>
                    }
                )
            }
        </Box>
    </Box>;
}

const DesktopContent: React.FC<ContentProps> = (
    props: ContentProps,
) => {
    const {
        onClose,
        categories,
        onRetry,
        onSelect,
        onAllSelect,
    } = props;

    return <Box
        sx={{
            backgroundColor: 'primary.light',
            justifyContent: 'space-between',
            borderRadius: '16px',
            position: 'absolute',
            right: '0px',
            width: '400px'
        }}
    >
        <div
            style={{
                display: 'flex',
                justifyContent: 'end'
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    padding: '0px',
                    marginTop: '24px',
                    marginRight: '24px',
                    color: 'primary.main'
                }}
            >
                <Close
                    fontSize="large"
                />
            </IconButton>
        </div>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '16px 60px 78px 60px'
            }}
        >
            {
                categories.when(
                    (data) => {
                        return <>
                            <Button
                                key={'menu_item_all'}
                                variant="text"
                                onClick={() => {
                                    onClose();
                                    onAllSelect();
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                    variant="h5"
                                >
                                    All products
                                </Typography>
                            </Button>
                            {
                                data.map(
                                    (item) => {
                                        return <Button
                                            key={'menu_button_' + item.localisedValue}
                                            variant="text"
                                            onClick={() => {
                                                onClose();
                                                onSelect(item);
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                            >
                                                {item.localisedValue}
                                            </Typography>
                                        </Button>
                                    }
                                )
                            }
                        </>
                    },
                    (error) => {
                        return <Button
                            onClick={onRetry}
                        >
                            RETRY
                        </Button>
                    },
                    () => {
                        return <div
                            style={{
                                display: 'flex',
                                justifyContent: "center"
                            }}
                        >
                            <CircularProgress />
                        </div>
                    }
                )
            }
        </Box>
    </Box>
}

export const AppBarMenuDrawer: React.FC<AppBarMenuDrawerProps> = (
    props: AppBarMenuDrawerProps,
) => {
    const {
        categories,
        retry,
    } = categoriesStore();

    const {
        setFilter,
        clearFilters,
    } = searchBuilder();

    const {
        isOpen,
        onClose,
    } = props;


    const navigate = useNavigate();

    const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));

    function onSelect(category: Category) {
        setFilter({
            category: category,
        });
        onClose();
        navigate("/search");
    }

    function onAllSelect() {
        clearFilters();
        onClose();
        navigate("/search");
    }

    if (isMobile) {
        return <Drawer
            open={isOpen}
            onClose={onClose}
            anchor='top'
        >
            <div>
                <MobileContent
                    onClose={onClose}
                    categories={categories}
                    onRetry={retry}
                    onSelect={onSelect}
                    onAllSelect={onAllSelect}
                />
            </div>
        </Drawer>
    } else {
        return <Modal
            open={isOpen}
            onClose={onClose}
            sx={{
                top: "20px",
                right: "20px"
            }}
        >
            <div>
                <DesktopContent
                    onClose={onClose}
                    categories={categories}
                    onRetry={retry}
                    onSelect={onSelect}
                    onAllSelect={onAllSelect}
                />
            </div>
        </Modal>
    }
}