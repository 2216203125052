import React, { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { DeleteProductFacetModal } from "./delete_product_facet_model.tsx";
import { Delete } from "@mui/icons-material";
import { StyledContainer } from "../../../components/molecules/styled_container.tsx";
import { Align, Alignment } from "../../../components/atoms/align.tsx";

type Item = {
    _id: string,
    localisedValue: string,
    canonicalValue: string,
}

type LocalisedCanonicalListProps = {
    title: string,
    items: Item[],
    deleteItem: (item: Item) => Promise<void>,
}

export const LocalisedCanonicalList: React.FC<LocalisedCanonicalListProps> = (
    props: LocalisedCanonicalListProps,
) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Item>();
    const {
        title,
        items,
        deleteItem,
    } = props;

    if (!items.length) {
        return <Align
            alignment={Alignment.center}
        >
            <Typography
                variant="subtitle1"
                align='center'
            >
                {"No " + title}
            </Typography>
        </Align>;
    }

    return <>
        <DeleteProductFacetModal
            valueName={selectedItem?.localisedValue ?? ""}
            onCancel={() => setVisible(false)}
            onConfirm={() => {
                deleteItem(selectedItem!);
                setVisible(false);
            }}
            visible={visible}
        />
        <List
            dense={true}
            sx={{
                maxWidth: "360px",
                minWidth: "300px"
            }}
        >
            {
                items.map(
                    (el) => <StyledContainer
                        key={el.canonicalValue}
                        margin="0px 0px 8px 0px"
                    >
                        <ListItem>
                            <ListItemText
                                primary={"Localised: " + el.localisedValue}
                                secondary={"Canonical: " + el.canonicalValue}
                            />
                            <ListItemIcon
                                onClick={() => {
                                    setSelectedItem(el);
                                    setVisible(true);
                                }}
                                sx={{
                                    cursor: "pointer",
                                }}
                            >
                                <Delete color='error' />
                            </ListItemIcon>
                        </ListItem>
                    </StyledContainer>
                )
            }
        </List >
    </>
}