import React, { PropsWithChildren } from "react"

export enum Alignment {
    left,
    topLeft,
    top,
    topRight,
    right,
    bottomRight,
    bottom,
    bottomLeft,
    center,
}

type AlignProps = PropsWithChildren & {
    alignment: Alignment,
};

type _AlignmentProps = {
    justifyContent: "start" | "center" | "end",
    alignItems: "start" | "center" | "end",
}

export const Align: React.FC<AlignProps> = (props: AlignProps) => {
    const {
        alignment,
        children
    } = props;

    var alignmentProps: _AlignmentProps;

    switch (alignment) {
        case (Alignment.left): alignmentProps = {
            justifyContent: "start",
            alignItems: "center",
        }
            break;
        case (Alignment.topLeft): alignmentProps = {
            justifyContent: "start",
            alignItems: "start",
        }
            break;
        case (Alignment.top): alignmentProps = {
            justifyContent: "center",
            alignItems: "start",
        }
            break;
        case (Alignment.topRight): alignmentProps = {
            justifyContent: "end",
            alignItems: "start",
        }
            break;
        case (Alignment.right): alignmentProps = {
            justifyContent: "end",
            alignItems: "center",
        }
            break;
        case (Alignment.bottomRight): alignmentProps = {
            justifyContent: "end",
            alignItems: "end",
        }
            break;
        case (Alignment.bottom): alignmentProps = {
            justifyContent: "center",
            alignItems: "end",
        }
            break;
        case (Alignment.bottomLeft): alignmentProps = {
            justifyContent: "start",
            alignItems: "end",
        }
            break;
        case (Alignment.center): alignmentProps = {
            justifyContent: "center",
            alignItems: "center",
        }
            break;
    }

    return <div
        style={{
            width: "100%",
            height: "100%",
            display: "flex",
            ...alignmentProps,
        }}
    >
        {children}
    </div>
}