import React, { useState } from 'react';
import { imageManagementStore } from '../../data/states/image_management_store.ts';
import { Image } from '../../data/entities/image.ts';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Align, Alignment } from '../../components/atoms/align.tsx';
import { ImagesDisplay } from './components/images_display.tsx';
import { FloatingActionButton } from '../../components/atoms/floating_action_button.tsx';
import { Add } from '@mui/icons-material';
import { UploadImageModal } from './components/upload_image_modal.tsx';

export const ImageTab: React.FC = () => {
    const { images, createImage, deleteImage, retry } = imageManagementStore(
        (state) => state,
    );
    const [modalIsVisible, setModalIsVisible] = useState(false);

    return images.when(
        (data: Image[]) => {
            return <>
                <UploadImageModal
                    visible={modalIsVisible}
                    onSubmit={(file: File) => {
                        createImage(file).then(
                            () => { }
                        ).catch(
                            (e) => {
                                alert(e);
                            }
                        );
                    }}
                    onClose={() => setModalIsVisible(false)}
                />
                <Align
                    alignment={Alignment.top}
                >
                    <ImagesDisplay
                        images={data}
                        deleteImage={async (image) => {
                            deleteImage(image._id);
                        }}
                    />
                </Align>
                <FloatingActionButton
                    onClick={() => {
                        setModalIsVisible(true);
                    }}
                >
                    <Add />
                </FloatingActionButton>
            </>;
        },
        (error: Error) => {
            return <Align
                alignment={Alignment.center}
            >
                <div>
                    <Typography
                        variant='h5'
                        sx={{
                            pb: "16px"
                        }}
                    >
                        Error fetching images
                    </Typography>
                    <Align
                        alignment={Alignment.center}
                    >
                        <Button
                            variant='contained'
                            onClick={retry}
                            sx={{
                                width: "120px"
                            }}
                        >
                            RETRY
                        </Button>
                    </Align>
                </div>
            </Align >
        },
        () => {
            return <CircularProgress />;
        },
    );
};