import { Box, Typography, TextField, Drawer } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { searchBuilder } from "../../data/stores/search_builder_store.tsx";

type AppBarMobileSearchDrawerProps = {
    isOpen: boolean,
    onClose: () => void,
}

export const AppBarMobileSearchDrawer: React.FC<AppBarMobileSearchDrawerProps> = (
    props: AppBarMobileSearchDrawerProps,
) => {
    const navigate = useNavigate();
    const { params, setKeywords } = searchBuilder();
    const [query, setQuery] = useState<string | undefined>(params.keywords);

    const {
        isOpen,
        onClose,
    } = props;

    return <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="top"
    >
        <Box
            sx={{
                backgroundColor: 'primary.light',
                padding: '24px'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography>
                    Peachy Tea Store
                </Typography>
                <Close
                    onClick={onClose}
                />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '42px 0px'
                }}
            >
                <TextField
                    id="keywords_field"
                    type="text"
                    color="primary"
                    sx={{
                        fieldset: {
                            borderColor: "transparent",
                            ":focus": {
                                borderColor: "transparent",
                            }
                        },
                        borderColor: "transparent",
                        ":focus": {
                            borderColor: "transparent",
                        },
                    }}
                    value={query}
                    onChange={(v) => {
                        setQuery(v.target.value);
                    }}
                    slotProps={{
                        input: {
                            endAdornment: <Search
                                onClick={() => {
                                    onClose();
                                    setKeywords(query);
                                    setQuery(undefined);
                                    navigate('/search')
                                }}
                            />,
                            style: {
                                borderRadius: "16px",
                                backgroundColor: 'white',
                                borderColor: "transparent",
                            },
                        },
                    }}
                    placeholder="Type your search here..."
                />
            </Box>
        </Box>
    </Drawer>;
}