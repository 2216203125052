import { Box, Skeleton, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type HomeSectionHeadingProps = PropsWithChildren;

export const HomeSectionHeadingLoader: React.FC = () => {
    return <Box
        textAlign='center'
    >
        <Skeleton
            sx={{
                padding: '0px',
                height: {
                    xs: "20px",
                    sm: "24px",
                    md: "28px",
                    lg: "32px",
                    xl: "32px",
                },
                width: {
                    xs: "140px",
                    sm: "140px",
                    md: "200px",
                    lg: "240px",
                    xl: "240px",
                },
                marginTop: {
                    xs: 8,
                    sm: 8,
                    md: 12,
                    lg: 20,
                    xl: 20,
                },
                marginBottom: {
                    xs: 3,
                    sm: 4,
                    md: 5,
                    lg: 6,
                    xl: 6,
                }
            }}
        />
    </Box>
}

export const HomeSectionHeading: React.FC<HomeSectionHeadingProps> = (
    props: HomeSectionHeadingProps,
) => {
    const {
        children
    } = props;

    return <Typography
        color="text.primary"
        align="center"
        fontSize={{
            xs: 22,
            sm: 22,
            md: 28,
            lg: 32,
            xl: 32,
        }}
        marginTop={{
            xs: 8,
            sm: 8,
            md: 12,
            lg: 20,
            xl: 20,
        }}
        marginBottom={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 5,
        }}
    >
        {children}
    </Typography>
}