import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

const colors = {
  primary: {
    main: "#8d00da",
    light: "#a333e1",
    dark: "#620098",
    contrastText: "#fff",
  },
  secondary: {
    main: "#ffab40",
    light: "#FFBB66",
    dark: "#B2772C",
    contrastText: "#fff",
  },
  text: {
    primary: "#161616",
    secondary: "#424242",
    disabled: "#878787",
  },
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: colors.primary,
    secondary: colors.secondary,
    text: colors.text,
  },
  spacing: 4,
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontWeight: "bold",
          color: '#996FD8',
          padding: "8px 16px",
          paddingBottom: "16px",
          textAlign: "center",
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "16px",
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "8px",
          margin: "0px",
          fontWeight: "600",
          borderWidth: "2px",
          borderRadius: "8px",
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
          margin: "0px",
          fontWeight: "600",
          borderWidth: "2px",
          borderRadius: "8px",
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        margin: "dense",
      },
      styleOverrides: {
        root: {
          margin: "0px",
          borderRadius: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "0px",
        }
      },
    }
  },
};

export const theme = createTheme(
  themeOptions,
);

export const responsiveTheme = responsiveFontSizes(theme);

