import { DialogContent, DialogTitle, Dialog, Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Close, CloudUpload } from "@mui/icons-material";
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { DialogActionButtons } from "../../../components/molecules/dialog_action_buttons.tsx";
import { ImageContainer } from "../../../components/atoms/image_container.tsx";

type UploadImageModalProps = {
    onSubmit: (value: File) => void;
    onClose: () => void,
    visible: boolean,
}

export const UploadImageModal: React.FC<UploadImageModalProps> = (props: UploadImageModalProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);


    const {
        onSubmit,
        onClose,
        visible,
    } = props;

    const handleClick = () => {
        document.getElementById("file-input")?.click();
    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(null)
            return
        }

        setSelectedFile(event.target.files[0]);
    };

    const handleClose = () => {
        setSelectedFile(null);
        onClose();
    }

    const removeSelection = () => {
        setSelectedFile(null);
    }

    return <Dialog
        open={visible}
        onClose={handleClose}
    >
        <DialogContent>
            <DialogTitle>
                Upload image
            </DialogTitle>
            <Box
                border={"1px solid"}
                borderRadius={"8px"}
                color={"divider"}
                width={"340px"}
                height={"280px"}
            >
                {selectedFile ?
                    <ImageContainer
                        src={URL.createObjectURL(selectedFile)}
                        fit="cover"
                        width={340}
                        height={280}
                    >
                        <Align
                            alignment={Alignment.topRight}
                        >
                            <IconButton
                                onClick={removeSelection}
                                sx={{
                                    padding: "16px"
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Align>
                    </ImageContainer> :
                    <Align
                        alignment={Alignment.center}
                    >
                        <IconButton
                            onClick={handleClick}
                        >
                            <CloudUpload />
                        </IconButton>
                    </Align>
                }
            </Box>
        </DialogContent>
        <input
            hidden
            type="file"
            id="file-input"
            onChange={handleFileSelect}
        />
        <DialogActionButtons
            primaryLabel="Upload"
            primaryIsDisabled={!selectedFile}
            onPrimaryClick={() => {
                onSubmit(selectedFile!);
                removeSelection();
            }}
            secondaryLabel="Close"
            onSecondaryClick={() => {
                removeSelection();
                onClose();
            }}
        />
    </Dialog>;
}