import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

type StyledContainerProps = PropsWithChildren & {
    borderRadius?: string,
    border?: string,
    borderColor?: string,
    margin?: string,
    padding?: string,
}

export const StyledContainer: React.FC<StyledContainerProps> = (props: StyledContainerProps) => {
    const {
        borderRadius,
        border,
        borderColor,
        margin,
        padding,
        children
    } = props;

    return <Box
        sx={{
            borderRadius: borderRadius ?? "8px",
            border: border ?? '1px solid',
            borderColor: borderColor ?? "divider",
            margin: margin ?? "0px",
            padding: padding ?? "8px",
        }}
    >
        {children}
    </Box>;
}