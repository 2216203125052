import { Drawer, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { imageManagementStore } from "../../../data/states/image_management_store.ts";
import { apiClient } from "../../../data/network/api_client.ts";
import { Image } from "../../../data/entities/image.ts";
import { StringUtils } from "../../../utils/string_utils.ts";

type ImageSelectorDrawerProps = PropsWithChildren & {
    visible: boolean,
    onClose: () => void,
    onSelect: (image: Image) => void,
    selectedIds: string[],
}

export const ImageSelectorDrawer: React.FC<ImageSelectorDrawerProps> = (props: ImageSelectorDrawerProps) => {
    const { images } = imageManagementStore();
    const {
        visible,
        onClose,
        onSelect,
        selectedIds,
    } = props;

    return <Drawer
        anchor="bottom"
        open={visible}
        onClose={onClose}
        style={{
            zIndex: "1399",
        }}
    >
        {
            images.when(
                (data) => {
                    return <ImageList
                        cols={6}
                        sx={{
                            width: "100vw",
                            height: "60vh",
                        }}
                    >
                        {data.map((item) => (
                            <ImageListItem
                                key={item._id}
                                onClick={() => {
                                    onSelect(item);
                                }}
                                sx={{
                                    border: selectedIds.includes(item._id) ? "2px solid" : "none",
                                    borderColor: "green"
                                }}
                            >
                                <img
                                    src={apiClient.makeImageUrl(item.path)}
                                    alt={""}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={
                                        <Typography
                                            variant="body2"
                                        >
                                            {StringUtils.stripExt(item.path)}
                                        </Typography>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>;
                },
                (error) => {
                    return <></>;
                },
                () => {
                    return <></>;
                }
            )
        }
    </Drawer>;
}