import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { Product } from '../entities/product.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'

type State = {
    products: AsyncValue<Product[]>,
}

export type ICreateProductParams = {
    title: string,
    description: string,
    quantity: number,
    price: number,
    imageIds: string[],
    categoryId: string,
    featureIds: string[],
    rangeId?: string,
}

export type IEditProductParams = ICreateProductParams & {
    id: string,
}

type Actions = {
    getProducts: () => Promise<void>,
    createProduct: (
        createProductParams: ICreateProductParams,
    ) => Promise<void>
    editProduct: (
        params: IEditProductParams,
    ) => Promise<void>,
    deleteProduct: (
        id: string,
    ) => Promise<void>,
    retry: () => Promise<void>,
}

export const productManagementStore = create<State & Actions>((set) => ({
    products: new AsyncLoading<Product[]>(),
    getProducts: async () => {
        try {
            var result: Product[] = await apiClient.getProducts({});

            set(
                () => (
                    { products: new AsyncData<Product[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { products: new AsyncError<Product[]>(e) }
                )
            );
        }
    },
    createProduct: async (createProductParams: ICreateProductParams) => {
        try {
            var result: Product = await apiClient.createProduct(createProductParams);

            set(
                (state: State) => (
                    { products: new AsyncData([...(state.products as AsyncData<Product[]>).value, result]) }
                )
            );
        } catch (e) {
            alert(e);
        }
    },
    editProduct: async (params: IEditProductParams) => {
        try {
            var result: Product = await apiClient.editProduct(params);

            set(
                (state: State) => (
                    { products: new AsyncData([...(state.products as AsyncData<Product[]>).value.filter((v) => v._id !== params.id), result]) }
                )
            );
        } catch (e) {
            alert(e);
        }
    },
    deleteProduct: async (id: string) => {
        try {
            await apiClient.deleteProduct(
                id,
            );

            set(
                (state: State) => (
                    { products: new AsyncData((state.products as AsyncData<Product[]>).value.filter(item => item._id !== id)) }
                )
            );
        } catch (e) {

        }
    },
    retry: async () => {
        set(
            () => (
                { products: new AsyncLoading() }
            )
        );

        try {
            var result: Product[] = await apiClient.getProducts({});

            set(
                () => (
                    { products: new AsyncData<Product[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { products: new AsyncError<Product[]>(e) }
                )
            );
        }
    },
}))