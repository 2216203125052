import { TextField, Button, FormControl, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Align, Alignment } from '../../components/atoms/align.tsx'

type LoginProps = {
    handleLogin: (email: string, password: string) => Promise<void>,
}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    return <Align
        alignment={Alignment.center}
    >
        <FormControl>
            <TextField
                type='email'
                id='email'
                label="Email"
                onChange={(v) => {
                    setEmail(v.target.value);
                }}
            />
            <Box sx={{ m: 1 }} />
            <TextField
                type='password'
                id='password'
                label="Password"
                onChange={(v) => {
                    setPassword(v.target.value);
                }}
            />
            <Box sx={{ m: 2 }} />
            <Button
                variant='outlined'
                onClick={() => {
                    props.handleLogin(email, password);
                }}
            >
                <Typography variant='button'>
                    LOG IN
                </Typography>
            </Button>
        </FormControl>
    </Align>;
};