import React, { useEffect } from "react";
import { Box, Grid2, Skeleton, useMediaQuery } from "@mui/material";
import { featuredCategoriesStore } from "../../../data/stores/featured_categories_store.tsx";
import { FeaturedCategory } from "../../../data/entities/featured_category.ts";
import { useNavigate } from "react-router-dom";
import { searchBuilder } from '../../../data/stores/search_builder_store.tsx';
import { HomeSectionHeading, HomeSectionHeadingLoader } from "./home_section_heading.tsx";
import { Carousel } from "../../../components/organisms/carousel.tsx";
import { HomeFeaturedCategoryCard } from './home_featured_category_card.tsx';
import { appTheme } from "../../../app_theme.ts";

type Tuple3 = {
    $1: FeaturedCategory,
    $2?: FeaturedCategory,
    $3?: FeaturedCategory,
}

type Tuple2 = {
    $1: FeaturedCategory,
    $2?: FeaturedCategory,
}

const Loader: React.FC = () => {
    return <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        padding={{
            xs: '0px 48px',
            sm: '0px 48px',
            md: '0px 92px',
            lg: '0px 140px',
            xl: '0px 140px',
        }}
    >
        <HomeSectionHeadingLoader />
        <Skeleton
            width='100%'
            sx={{
                borderRadius: {
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 5,
                    xl: 5,
                },
                height: {
                    xs: '174px',
                    sm: '174px',
                    md: '224px',
                    lg: '640px',
                    xl: '640px',
                }
            }}
        />
        <Box
            paddingTop={{
                xs: 4,
                sm: 4,
                md: 5,
                lg: 5,
                xl: 5,
            }}
        >
            <Skeleton height='10px' width="200px" />
        </Box>
    </Box>
}

export const HomeCategoriesCarousel: React.FC = () => {
    const { featuredCategories, load } = featuredCategoriesStore();
    const { setFilter } = searchBuilder();
    const navigate = useNavigate();
    const isSmall: boolean = useMediaQuery(appTheme.breakpoints.down('sm'));
    const isMedium: boolean = useMediaQuery(appTheme.breakpoints.down('lg'));

    useEffect(
        () => {
            load();
        },
        [load],
    )

    function onViewCategory(category: FeaturedCategory) {
        setFilter({
            category: {
                _id: category._id,
                localisedValue: category.localisedValue,
                canonicalValue: '',
            },
            clear: true,
        });
        navigate('/search')
    }

    function makeChildren(data: FeaturedCategory[]): JSX.Element[] {
        if (isSmall) {
            return data.map(
                (el) => <Grid2
                    margin='0px 48px'
                    columns={1}
                    height='174px'
                >
                    <HomeFeaturedCategoryCard
                        featuredCategory={el}
                        onSelect={() => {
                            onViewCategory(el);
                        }}
                        radius={16}
                    />
                </Grid2>
            );
        } else if (isMedium) {
            const formatted: Tuple2[] = [];

            for (let i = 0; i < data.length; i += 2) {
                const first: FeaturedCategory = data[i];
                const second: FeaturedCategory | undefined = data[i + 1];

                formatted.push({
                    $1: first,
                    $2: second,
                })
            }

            return formatted.map(
                (el) => <Grid2
                    margin={{
                        sm: '0px 48px',
                        md: '0px 96px'
                    }}
                    container
                    columns={2}
                    spacing='24px'
                    height='260px'
                >
                    <Grid2
                        size={1}
                        height='100%'
                    >
                        <HomeFeaturedCategoryCard
                            featuredCategory={el.$1}
                            onSelect={() => {
                                onViewCategory(el.$1);
                            }}
                            radius={16}
                        />
                    </Grid2>
                    {
                        el.$2
                            ? <Grid2
                                size={1}
                                height='100%'
                            >
                                <HomeFeaturedCategoryCard
                                    featuredCategory={el.$2}
                                    onSelect={() => {
                                        onViewCategory(el.$2!);
                                    }}
                                    radius={16}
                                />
                            </Grid2>
                            : <></>
                    }

                </Grid2>
            );
        } else {
            const formatted: Tuple3[] = [];

            for (let i = 0; i < data.length; i += 3) {
                const first: FeaturedCategory = data[i];
                const second: FeaturedCategory | undefined = data[i + 1];
                const third: FeaturedCategory | undefined = data[i + 2];

                formatted.push({
                    $1: first,
                    $2: second,
                    $3: third,
                })
            }
            return formatted.map(
                (el) => <Grid2
                    margin='0px 140px'
                    container
                    columns={10}
                    spacing='40px'
                    height='640px'
                >
                    <Grid2
                        size={6}
                        height='100%'
                    >
                        <HomeFeaturedCategoryCard
                            featuredCategory={el.$1}
                            onSelect={() => {
                                onViewCategory(el.$1);
                            }}
                            radius={20}
                        />
                    </Grid2>
                    {
                        el.$2
                            ? <Grid2
                                size={4}
                                container
                                display='flex'
                                flexDirection='column'
                                height='100%'
                            >
                                <Grid2
                                    height='300px'
                                >
                                    <HomeFeaturedCategoryCard
                                        featuredCategory={el.$2}
                                        onSelect={() => {
                                            onViewCategory(el.$2!);
                                        }}
                                        radius={20}
                                    />
                                </Grid2>
                                {
                                    el.$3
                                        ? <Grid2
                                            height='300px'
                                        >
                                            <HomeFeaturedCategoryCard
                                                featuredCategory={el.$3}
                                                onSelect={() => {
                                                    onViewCategory(el.$3!);
                                                }}
                                                radius={20}
                                            />
                                        </Grid2>
                                        : <></>
                                }
                            </Grid2>
                            : <></>
                    }
                </Grid2>
            );
        }
    }


    return featuredCategories.when(
        (data) => {
            return <>
                <HomeSectionHeading>
                    Categories
                </HomeSectionHeading>
                <Carousel
                    items={makeChildren(data)}
                />
            </>
        },
        (error) => {
            return <></>;
        },
        () => <Loader />
    );
}