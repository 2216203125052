import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { Product } from '../entities/product.ts'
type State = {
    products: AsyncValue<Product[]>,
}

type Actions = {
    load: () => Promise<void>,
}

export const bestSellersStore = create<State & Actions>((set) => ({
    products: new AsyncLoading<Product[]>(),
    load: async () => {
        try {
            const result: Product[] = await apiClient.getBestSellers();

            set(
                () => (
                    { products: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        products: new AsyncError(e)
                    }
                )
            );
        }
    },
}))