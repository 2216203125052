import React from "react"

type IndexIndicatorProps = {
    max: number,
    current: number,
    focusColor: string,
    color: string,
}

export const IndexIndicator: React.FC<IndexIndicatorProps> = (
    props: IndexIndicatorProps
) => {
    const {
        max,
        current,
        focusColor,
        color,
    } = props;

    const elements: React.JSX.Element[] = Array.from(Array(max), (_, i) => {
        return <div
            key={`index_indicator_for_${i}`}
            style={{
                borderRadius: 12,
                width: 10,
                height: 10,
                backgroundColor: current === i ? focusColor : color,
                margin: '0px 6px',
                transition: "all .5s ease",
                WebkitTransition: "all .5s ease",
                MozTransition: "all .5s ease"
            }}
        >

        </div>
    });

    return <div
        style={{
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        {elements}
    </div>;
}