import React, { useState } from 'react';
import { ICreateProductParams, IEditProductParams, productManagementStore } from '../../data/states/product_management_store.ts';
import { Product } from '../../data/entities/product.ts';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Align, Alignment } from '../../components/atoms/align.tsx';
import { ProductList } from './components/products_list.tsx';
import { FloatingActionButton } from '../../components/atoms/floating_action_button.tsx';
import { Add } from '@mui/icons-material';
import { CreateProductModal } from './components/create_product_modal.tsx';

export const ProductTab: React.FC = () => {
    const { products, createProduct, editProduct, deleteProduct, retry } = productManagementStore(
        (state) => state,
    );
    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
    const [productToEdit, setProductToEdit] = useState<Product>();
    const [duplicate, setDuplicate] = useState<Product>();

    return products.when(
        (data: Product[]) => {
            return <div
                style={{
                    marginBottom: "100px"
                }}
            >
                <CreateProductModal
                    visible={modalIsVisible}
                    onClose={() => {
                        setModalIsVisible(false)
                    }}
                    onSubmit={(value: ICreateProductParams | IEditProductParams) => {
                        if (productToEdit) {
                            editProduct(value as IEditProductParams);
                            setProductToEdit(undefined);
                            setModalIsVisible(false);
                        } else {
                            createProduct(value);
                        }
                    }}
                    productToEdit={productToEdit}
                    duplicate={duplicate}
                />
                <ProductList
                    products={data}
                    deleteProduct={async (product: Product) => deleteProduct(product._id)}
                    editProduct={(product: Product) => {
                        setProductToEdit(product);
                        setModalIsVisible(true);
                    }}
                    duplicateProduct={(product: Product) => {
                        setDuplicate(product);
                        setModalIsVisible(true);
                    }}
                />
                <FloatingActionButton
                    onClick={() => {
                        setModalIsVisible(true);
                    }}
                >
                    <Add />
                </FloatingActionButton>
            </div>
        },
        (error: Error) => {
            return <Align
                alignment={Alignment.center}
            >
                <div>
                    <Typography
                        variant='h5'
                        sx={{
                            pb: "16px"
                        }}
                    >
                        Error fetching products
                    </Typography>
                    <Align
                        alignment={Alignment.center}
                    >
                        <Button
                            variant='contained'
                            onClick={retry}
                            sx={{
                                width: "120px"
                            }}
                        >
                            RETRY
                        </Button>
                    </Align>
                </div>
            </Align >
        },
        () => {
            return <Align
                alignment={Alignment.center}
            >
                <CircularProgress />
            </Align>;
        },
    );
};