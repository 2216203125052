import React from 'react';
import { HomeBanner } from './components/home_banner.tsx';
import { HomeCategoriesCarousel } from './components/home_categories_carousel.tsx';
import { HomeBestSellersGrid } from './components/home_best_sellers_grid.tsx';
import { Box, Button } from '@mui/material';
import { HomeProductsByRange } from './components/home_products_by_range.tsx';
import { searchBuilder } from '../../data/stores/search_builder_store.tsx';
import { useNavigate } from 'react-router-dom';

export const Home: React.FC = () => {
  const { clearFilters } = searchBuilder();
  const navigate = useNavigate();

  return <>
    <HomeBanner />
    <HomeCategoriesCarousel />
    <HomeBestSellersGrid />
    <Box
      textAlign="center"
    >
      <Button
        onClick={() => {
          clearFilters();
          navigate("/search");
        }}
        variant='filled'
        sx={{
          marginTop: {
            xs: "20px",
            sm: "20px",
            md: "28px",
            lg: "32px",
            xl: "22px",
          },
          marginBottom: {
            xs: "40px",
            sm: "40px",
            md: "52px",
            lg: "64px",
            xl: "64px",
          },
        }}
      >
        View all products
      </Button>
    </Box>
    <HomeProductsByRange />
  </>
};