import { DialogContent, DialogActions, DialogTitle, Button, Dialog, Typography, ImageListItem, ImageListItemBar, ImageList } from "@mui/material";
import React from "react";
import { apiClient } from "../../../data/network/api_client.ts";
import { StringUtils } from "../../../utils/string_utils.ts";
import { Image } from "../../../data/entities/image.ts";

type DeleteImageConfirmationModalProps = {
    image: Image,
    onConfirm: () => void;
    onCancel: () => void,
    visible: boolean,
}

export const DeleteImageConfirmationModal: React.FC<DeleteImageConfirmationModalProps> = (props: DeleteImageConfirmationModalProps) => {
    const {
        image,
        onConfirm,
        onCancel,
        visible,
    } = props;

    return <Dialog
        open={visible}
        onClose={onCancel}
    >
        <DialogTitle>
            Confirm
        </DialogTitle>
        <DialogContent>
            <Typography
                variant="body1"
                align="center"
                sx={{
                    margin: "0px 32px"
                }}
            >
                You are about to delete
            </Typography>
            <ImageList
                cols={1}
                sx={{
                    width: 300,
                    height: 300
                }}
            >
                <ImageListItem
                    key={image._id}
                >
                    <img
                        src={apiClient.makeImageUrl(image.path)}
                        alt={""}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={
                            <Typography
                                variant="body2"
                            >
                                {StringUtils.stripExt(image.path)}
                            </Typography>
                        }
                    />
                </ImageListItem>
            </ImageList>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onCancel}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button
                onClick={onConfirm}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}