import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { User } from '../../data/entities/user.ts';
import { apiClient } from '../../data/network/api_client.ts';

type IAuthContext = {
    user?: User | null,
    handleLogin: (email: string, password: string) => Promise<void>,
    handleLogout: () => Promise<void>,
}


const AuthContext = createContext<IAuthContext | undefined>(undefined);

type AuthProviderProps = PropsWithChildren;

function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<User | null>();

    async function handleLogin(email: string, password: string) {
        try {
            const result: User = await apiClient.login(
                email,
                password,
            );
            sessionStorage.setItem('jwt', result.token);
            setUser(result);
        } catch (e) {
            alert(e);
            setUser(null);
        }
    }

    async function handleLogout() {
        setUser(null);
    }

    return <AuthContext.Provider value={{
        user: user,
        handleLogin: handleLogin,
        handleLogout: handleLogout,
    }}>
        {children}
    </AuthContext.Provider>;
}

function useAuth() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used inside of an AuthProvider")
    }
    return context;
}

export {
    AuthProvider, 
    useAuth,
}