import { create } from 'zustand'
import { ProductRange } from '../entities/product_range.ts'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'

type State = {
    productRanges: AsyncValue<ProductRange[]>,
}

type Actions = {
    getProductRanges: () => Promise<void>,
    createProductRange: (
        name: string,
    ) => Promise<void>,
    deleteProductRange: (
        id: string,
    ) => Promise<void>,
    retry: () => Promise<void>,
}

export const productRangeManagementStore = create<State & Actions>((set) => ({
    productRanges: new AsyncLoading<ProductRange[]>(),
    getProductRanges: async () => {
        try {
            const result: ProductRange[] = await apiClient.getProductRanges();

            set(
                () => (
                    { productRanges: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        productRanges: new AsyncError(e)
                    }
                )
            );
        }
    },
    createProductRange: async (
        name: string,
    ) => {
        try {
            const result: ProductRange = await apiClient.createProductRange(
                name,
            );

            set(
                (state: State) => (
                    {
                        productRanges: new AsyncData([...(state.productRanges as AsyncData<ProductRange[]>).value, result])
                    }
                )
            );
        } catch (e) {
            alert("Failed to create category");
        }
    },
    deleteProductRange: async (id: string) => {
        try {
            await apiClient.deleteProductRange(
                id,
            );

            set(
                (state: State) => (
                    { productRanges: new AsyncData((state.productRanges as AsyncData<ProductRange[]>).value.filter(item => item._id !== id)) }
                )
            );
        } catch (e) {
            alert(e);
        }
    },
    retry: async () => {
        set(
            () => (
                { productRanges: new AsyncLoading() }
            )
        );

        try {
            const result: ProductRange[] = await apiClient.getProductRanges();

            const newState: State = {
                productRanges: new AsyncData(result),
            }

            set(
                (state: State) => (
                    newState
                )
            );
        } catch (e) {
            set(
                () => (
                    { productRanges: new AsyncError(e) }
                )
            );
        }
    },
}))