import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { Product } from '../entities/product.ts'
import { ProductRange } from '../entities/product_range.ts'

type State = {
    products: AsyncValue<Product[]>,
}

type Actions = {
    load: (range: ProductRange) => Promise<void>,
}

export const relatedProductsStore = create<State & Actions>((set, state) => ({
    products: new AsyncLoading<Product[]>(),
    load: async (range: ProductRange) => {
        set(
            () => (
                {
                    products: new AsyncLoading<Product[]>()
                }
            )
        )
        try {
            const result: Product[] = await apiClient.getProducts({
                range: range,
            });

            set(
                () => (
                    { products: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        products: new AsyncError(e)
                    }
                )
            );
        }
    },
}))