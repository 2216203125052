import { Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchBuilder } from "../../data/stores/search_builder_store.tsx";

export const AppBarDesktopSearchField: React.FC = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>();
    const { setKeywords } = searchBuilder();

    return <Box
        sx={{
            padding: '8px 16px 8px 16px',
            backgroundColor: 'primary.light',
            borderRadius: "16px",
            width: '30%',
        }}
    >
        <TextField
            id="search_field"
            placeholder="Type your search here..."
            size="small"
            fullWidth
            sx={{
                borderRadius: '16px',
                float: 'right',
                marginTop: '2px',
            }}
            onChange={(v) => {
                setQuery(v.target.value);
            }}
            variant="standard"
            slotProps={{
                input: {
                    disableUnderline: true,
                    endAdornment: <Search
                        onClick={() => {
                            setKeywords(query);
                            navigate('/search');
                        }}
                        color="primary"
                        sx={{
                            cursor: 'pointer'
                        }}
                    />
                },

            }}
        />
    </Box>

    // return <Box
    //     sx={{
    //         transition: "all .5s ease",
    //         WebkitTransition: "all .5s ease",
    //         MozTransition: "all .5s ease",
    //         width: inputVisible ? "30%" : 24,
    //         backgroundColor: inputVisible ? 'primary.light' : 'white',
    //         borderRadius: '16px',
    //         padding: '8px 6px 6px 24px',
    //         paddingLeft: inputVisible ? '24px' : '0px',
    //         display: 'flex',
    //         justifyContent: 'space-between',
    //         marginTop: '4px',
    //         height: '32px',
    //     }}
    // >
    //     <TextField
    //         id="search_field"
    //         placeholder="Type your search here..."
    //         // size="small"
    //         fullWidth
    //         sx={{
    //             flexGrow: 1,
    //             float: 'right',
    //             display: inputVisible ? undefined : 'none',
    //         }}
    //         onChange={(v) => {
    //             setQuery(v.target.value);
    //         }}
    //         variant="standard"
    //         slotProps={{
    //             input: {
    //                 disableUnderline: true,
    //             }
    //         }}
    //         onBlur={() => setInputVisible(false)}
    //     />
    //     {
    //         inputVisible
    //             ? <Search
    //                 onClick={() => {
    //                     setKeywords(query);
    //                     navigate('/search')
    //                 }}
    //                 color="info"
    //                 sx={{
    //                     cursor: 'pointer'
    //                 }}
    //             />
    //             : <Search
    //                 onClick={() => {
    //                     setInputVisible(true);
    //                 }}
    //                 color="primary"
    //                 sx={{
    //                     cursor: 'pointer'
    //                 }}
    //             />
    //     }
    // </Box>
}