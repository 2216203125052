import { AppBar, IconButton, Typography, useMediaQuery, useScrollTrigger } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PropsWithChildren } from "react";
import { theme } from "../../theme.ts";
import { Search, Menu } from "@mui/icons-material";
import { AppBarMenuDrawer } from "./app_bar_menu_drawer.tsx";
import { AppBarMobileSearchDrawer } from "./app_bar_mobile_search_drawer.tsx";
import { useNavigate } from "react-router-dom";
import { categoriesStore } from "../../data/stores/categories_store.tsx";
import { AppBarDesktopSearchField } from './app_bar_desktop_search_field.tsx';
import { useLocation } from 'react-router-dom'

type LayoutProps = PropsWithChildren;

interface Props {
    children?: React.ReactElement;
}

function ElevationScroll(props: Props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window,
    });
  
    return children
      ? React.cloneElement(children, {
          elevation: trigger ? 3 : 0,
        })
      : null;
  }

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const {
        children,
    } = props;

    const { load } = categoriesStore();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    useEffect(
        () => {
            load();
        },
        [load],
    )

    useEffect(
        () => {
            if (location.pathname !== "/") {
                window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
            }
        },
        [location]
    )

    return <>
        <AppBarMenuDrawer
            isOpen={openMenu}
            onClose={() => setOpenMenu(false)}
        />
        {
            isMobile
                ? <AppBarMobileSearchDrawer
                    isOpen={openSearch}
                    onClose={() => setOpenSearch(false)}
                />
                : <></>
        }
        <ElevationScroll>
            <AppBar
                elevation={0}
                sx={{
                    margin: 0,
                    minHeight: 0,
                    padding: isMobile ? "24px" : "24px 80px",
                    backgroundColor: 'white',
                }}
                position="sticky"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h6"
                        color="primary"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            cursor: useLocation().pathname !== "/" ? 'pointer' : "default",
                        }}
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Peachy Tea Store
                    </Typography>
                    {
                        isMobile
                            ? <IconButton
                                onClick={() => setOpenSearch(true)}
                                sx={{
                                    padding: '0px',
                                    margin: '0px'
                                }}
                            >
                                <Search
                                    color="primary"
                                />
                            </IconButton>
                            : <AppBarDesktopSearchField />
                    }
                    <IconButton
                        onClick={() => setOpenMenu(true)}
                        sx={{
                            padding: '0px',
                            margin: '0px',
                            marginLeft: '16px'
                        }}
                    >
                        <Menu
                            color="primary"
                        />
                    </IconButton>
                </div>
            </AppBar>
        </ElevationScroll>
        {children}
    </>
}