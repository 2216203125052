import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { ProductsByRange } from '../entities/products_by_range.ts'

type State = {
    productsByRange: AsyncValue<ProductsByRange[]>,
}

type Actions = {
    load: () => Promise<void>,
}

export const productsByRangeStore = create<State & Actions>((set) => ({
    productsByRange: new AsyncLoading<ProductsByRange[]>(),
    load: async () => {
        try {
            const result: ProductsByRange[] = await apiClient.getProductsByRange();

            set(
                () => (
                    { productsByRange: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        productsByRange: new AsyncError(e)
                    }
                )
            );
        }
    },
}))