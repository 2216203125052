import React, { useState } from "react";
import { IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { Image } from "../../../data/entities/image.ts";
import { DeleteImageConfirmationModal } from "./delete_image_confirmation_modal.tsx";
import { apiClient } from "../../../data/network/api_client.ts";
import { StringUtils } from '../../../utils/string_utils.ts';
import { Delete } from '@mui/icons-material';

type ImagesDisplayProps = {
    images: Image[],
    deleteImage: (images: Image) => Promise<void>,
}

export const ImagesDisplay: React.FC<ImagesDisplayProps> = (props: ImagesDisplayProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [image, setImage] = useState<Image>();
    const {
        images,
        deleteImage,
    } = props;

    if (!images.length) {
        return <Align
            alignment={Alignment.center}
        >
            <Typography
                variant="subtitle1"
                align='center'
            >
                No images
            </Typography>
        </Align>;
    }
    return <>
        {
            image ? <DeleteImageConfirmationModal
                image={image}
                onCancel={() => setVisible(false)}
                onConfirm={() => {
                    deleteImage(image!).then(
                        () => setVisible(false),
                    );
                }}
                visible={visible}
            /> : <></>
        }
        <ImageList
            cols={6}
            sx={{
                width: "80vw",
            }}
        >
            {images.map((item) => (
                <ImageListItem
                    key={item._id}
                >
                    <img
                        src={apiClient.makeImageUrl(item.path)}
                        alt={""}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={
                            <Typography
                                variant="body2"
                            >
                                {StringUtils.stripExt(item.path)}
                            </Typography>
                        }
                        actionIcon={
                            <IconButton
                                onClick={() => {
                                    setImage(item);
                                    setVisible(true);
                                }}
                            >
                                <Delete
                                    color="error"
                                />
                            </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
    </>
}