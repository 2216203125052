import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { FeaturedCategory } from '../entities/featured_category.ts'
type State = {
    featuredCategories: AsyncValue<FeaturedCategory[]>,
}

type Actions = {
    load: () => Promise<void>,
}

export const featuredCategoriesStore = create<State & Actions>((set) => ({
    featuredCategories: new AsyncLoading<FeaturedCategory[]>(),
    load: async () => {
        try {
            const result: FeaturedCategory[] = await apiClient.getFeaturedCategories();

            set(
                () => (
                    { featuredCategories: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        featuredCategories: new AsyncError(e)
                    }
                )
            );
        }
    },
}))