import React from "react";
import { appTheme } from "../../../app_theme.ts";
import { Box, Button, Grid2, Typography, useMediaQuery } from "@mui/material";
import logo from '../../../assets/images/logo.svg';
import { Spacer } from '../../../components/atoms/spacer.tsx';
import { useNavigate } from "react-router-dom";
import { searchBuilder } from "../../../data/stores/search_builder_store.tsx";


const Copy: React.FC = () => {
    const { clearFilters } = searchBuilder();
    const navigate = useNavigate();

    return <Grid2
        key={"home_banner_copy"}
        size={{
            xs: 1,
            sm: 1,
            md: 9,
            lg: 9,
            xl: 9,
        }}
        container
        flexDirection="column"
        justifyContent='center'
    >
        <Typography
            variant="h3"
            align="center"
            color="text.primary"
            fontSize={{
                xs: 30,
                sm: 34,
                md: 42,
                lg: 54,
                xl: 60,
            }}
        >
            Hello and welcome!
        </Typography>
        <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            fontSize={{
                xs: 20,
                sm: 24,
                md: 28,
                lg: 40,
                xl: 40,
            }}
        >
            to Peachy Tea store
        </Typography>
        <Spacer
            axis="vertical"
            pixels={16}
        />
        <Box
            textAlign="center"
        >
            <Button
                variant="contained"
                onClick={() => {
                    clearFilters();
                    navigate("/search");
                }}
            >
                View all products
            </Button>
        </Box>
    </Grid2>;
}

const Image: React.FC = () => {
    return <Grid2
        key={"home_banner_logo"}
        size={6}
    >
        <img
            src={logo}
            alt=""
            style={{
                width: "100%",
                height: "100%",
            }}
        />
    </Grid2>
}

export const HomeBanner: React.FC = () => {
    const isMobile = useMediaQuery(appTheme.breakpoints.down('md'));
    const copy = <Copy key={"home_banner_copy"} />;
    const logo = <Image key={"home_banner_image"} />;
    var items = [
        isMobile ? logo : copy,
        isMobile ? copy : logo,
    ];

    return <Box
        maxWidth="100%"
        sx={{
            backgroundColor: 'primary.light',
        }}
        paddingTop={12}
        paddingBottom={16}
    >
        <Grid2
            container
            columns={{
                xs: 1,
                sm: 1,
                md: 17,
                lg: 17,
                xl: 17,
            }}
            justifyContent='center'
            spacing={isMobile ? 8 : 0}
        >

            {
                items
            }
        </Grid2>
    </Box>
}