import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { Category } from '../entities/category.ts'

type State = {
    categories: AsyncValue<Category[]>,
}

type Actions = {
    load: () => Promise<void>,
    retry: () => Promise<void>,
}

export const categoriesStore = create<State & Actions>((set, state) => ({
    categories: new AsyncLoading<Category[]>(),
    load: async () => {
        try {
            const result: Category[] = await apiClient.getCategories();
            
            if(state().categories instanceof AsyncData){
                return;
            }

            set(
                () => {
                    return (
                        { categories: new AsyncData(result) }
                    );
                }
            );
        } catch (e) {
            set(
                () => (
                    {
                        categories: new AsyncError(e)
                    }
                )
            );
        }
    },
    retry: async () => {
        set(
            () => (
                { categories: new AsyncLoading() }
            )
        );
        try {
            const result: Category[] = await apiClient.getCategories();

            set(
                () => (
                    { categories: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        categories: new AsyncError(e)
                    }
                )
            );
        }
    },
}))