import React, { useState } from "react"
import { Product } from '../../../data/entities/product.ts'
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { ImageContainer } from '../../../components/atoms/image_container.tsx';
import { apiClient } from '../../../data/network/api_client.ts';
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { ChevronLeft, ChevronRight, ContentCopy, Delete, Edit, Image } from "@mui/icons-material";
import { Spacer } from "../../../components/atoms/spacer.tsx";

type ManagementProductCardProps = {
    product: Product,
    onEdit: () => void,
    onDuplicate: () => void,
    onDelete: () => void,
}

export const ManagementProductCard: React.FC<ManagementProductCardProps> = (
    props: ManagementProductCardProps,
) => {
    const {
        product,
        onEdit,
        onDuplicate,
        onDelete,
    } = props;
    const [imageIndex, setImageIndex] = useState<number>(0);

    const handleImagePrev = () => {
        // Go to last image
        if (imageIndex === 0) {
            setImageIndex(product.images.length - 1);
        } else {
            setImageIndex(imageIndex - 1);
        }
    }

    const handleImageNext = () => {
        if (imageIndex === product.images.length - 1) {
            setImageIndex(0);
        } else {
            setImageIndex(imageIndex + 1);
        }
    }

    return <Box
        sx={{
            borderRadius: "8px",
            border: '1px solid',
            borderColor: 'divider',
            width: "320px"
        }}
    >
        {product.images.length ?
            <ImageContainer
                src={apiClient.makeImageUrl(product.images[imageIndex].path)}
                fit="cover"
                width={320}
                height={260}
            >
                <Align
                    alignment={Alignment.center}
                >
                    <div
                        style={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <IconButton
                            onClick={handleImagePrev}
                        >
                            <ChevronLeft />
                        </IconButton>
                        <IconButton
                            onClick={handleImageNext}
                        >
                            <ChevronRight />
                        </IconButton>
                    </div>
                </Align>
            </ImageContainer>
            :
            <Box
                sx={{
                    height: 260,
                    borderRadius: '8px',
                    border: "1px solid",
                    borderColor: 'divider',
                }}
            >
                <Align
                    alignment={Alignment.center}
                >
                    <Image
                        sx={{
                            width: 80,
                            height: 80,
                            color: "grey"
                        }}
                    />
                </Align>
            </Box>
        }
        <div
            style={{
                margin: "8px"
            }}
        >
            <div
                style={{
                    height: '48px'
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {product.title}
                </Typography>
            </div>
            <div
                style={{
                    height: '38px'
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        color: 'text.secondary'
                    }}
                >
                    {product.description}
                </Typography>
            </div>
            <Typography>
                {`R ${product.price}`}
            </Typography>
            <Typography>
                {`Quantity: ${product.quantity}`}
            </Typography>
            <Spacer
                axis="vertical"
                pixels={4}
            />
            <Chip
                label={product.category.localisedValue}
                size="small"
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    borderRadius: 4,
                }}
            />
            <Spacer
                axis="vertical"
                pixels={8}
            />
            <div>
                {
                    product.features.map(el => <Chip
                        key={el._id}
                        size="small"
                        label={el.localisedValue}
                    />)
                }
            </div>
            {
                product.range ?
                    <>
                        <Spacer
                            axis="vertical"
                            pixels={8}
                        />
                        <Chip
                            label={product.range.localisedValue}
                            size="small"
                            sx={{
                                backgroundColor: 'primary.light',
                                color: 'primary.contrastText',
                                borderRadius: 4,
                            }}
                        />
                    </>
                    : <></>
            }
        </div>
        <Align
            alignment={Alignment.right}
        >
            <IconButton
                onClick={onEdit}
            >
                <Edit />
            </IconButton>
            <IconButton
                onClick={onDuplicate}
            >
                <ContentCopy />
            </IconButton>
            <IconButton
                onClick={onDelete}
            >
                <Delete color="error" />
            </IconButton>
        </Align>
    </Box>
}