import { Category } from "../entities/category.ts";
import { Image } from "../entities/image.ts";
import { Product } from "../entities/product.ts";
import { ProductFeature } from "../entities/product_feature.ts";
import { User } from "../entities/user.ts";
import { ICreateProductParams, IEditProductParams } from "../states/product_management_store.ts";
import { FeaturedCategory } from '../entities/featured_category.ts';
import { ProductRange } from "../entities/product_range.ts";
import { ProductsByRange } from "../entities/products_by_range.ts";
import { SearchParams } from "../entities/search_params.ts";

export const BASE_URL = process.env.REACT_APP_BASE_URL!;

export type RequestParams = {
    path: string,
    method: string,
    headers?: object,
    body?: object | FormData,
    removeContentType?: boolean,
};

class _NetworkClient {
    public async makeRequest<ResponseType>(
        request: RequestParams
    ): Promise<ResponseType> {
        const headers = {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-API-KEY': process.env.REACT_APP_API_KEY!,
            ...request.headers,
        };

        if (request.removeContentType !== true) {
            headers["Content-Type"] = "application/json";
        }

        if (sessionStorage.getItem('jwt')) {
            headers["Authorization"] = "Bearer " + sessionStorage.getItem('jwt');
        }
        let body: string | FormData | undefined;
        if (request.body) {
            if (request.body instanceof FormData) {
                body = request.body;
            } else {
                body = JSON.stringify(request.body);
            }
        }
        try {
            var response: Response = await fetch(
                BASE_URL + request.path,
                {
                    headers: headers,
                    method: request.method,
                    body: body,
                }
            );
            const string = await response.text();
            const json = string === "" ? {} : JSON.parse(string);
            if (response.ok) {
                return json as ResponseType;
            } else {
                if (response.status === 401) {
                    sessionStorage.removeItem("jwt");
                }
                throw new Error(json.message)
            }
        } catch (e) {
            throw e;
        }
    }
}

class ApiClient {
    client: _NetworkClient;

    constructor() {
        this.client = new _NetworkClient();
    }

    async login(email: string, password: string): Promise<User> {
        return this.client.makeRequest<User>({
            path: "user/login",
            method: "POST",
            body: {
                email: email,
                password: password,
            }
        });
    }

    async getProducts(params: SearchParams): Promise<Product[]> {
        var queryString = '?';
        if(params.keywords){
            queryString += `q=${params.keywords}`;
        }
        if(params.category){
            queryString += `categoryId=${params.category._id}`;
        }
        if(params.feature){
            queryString += `featureId=${params.feature._id}`;
        }
        if(params.range){
            queryString += `rangeId=${params.range._id}`;
        }
        if(queryString.length === 1){
            queryString = "";
        }

        return this.client.makeRequest<Product[]>({
            path: "products" + queryString,
            method: "GET"
        });
    }

    async getCategories(): Promise<Category[]> {
        return await this.client.makeRequest<Category[]>({
            path: "categories",
            method: "GET"
        });
    }

    async getProductFeatures(): Promise<ProductFeature[]> {
        return this.client.makeRequest({
            path: "product-features",
            method: "GET"
        });
    }

    async getImages(): Promise<Image[]> {
        return this.client.makeRequest<Image[]>({
            path: "images",
            method: "GET"
        });
    }

    makeImageUrl(path: string): string {
        return BASE_URL + path;
    }

    async createCategory(name: string): Promise<Category> {
        return this.client.makeRequest<Category>({
            path: "categories",
            method: "POST",
            body: {
                name: name,
            }
        });
    }

    async deleteCategory(id: string): Promise<void> {
        return this.client.makeRequest<void>({
            path: "categories/" + id,
            method: "DELETE",
        });
    }

    async createProductFeature(name: string): Promise<ProductFeature> {
        return this.client.makeRequest<ProductFeature>({
            path: "product-features",
            method: "POST",
            body: {
                name: name,
            }
        });
    }

    async deleteProductFeature(id: string): Promise<void> {
        return this.client.makeRequest<void>({
            path: "product-features/" + id,
            method: "DELETE",
        });
    }

    async uploadImage(file: File): Promise<Image> {
        const formData: FormData = new FormData();
        formData.append("file", file);

        return this.client.makeRequest<Image>({
            path: "images",
            method: "POST",
            removeContentType: true,
            body: formData,
        });
    }

    async deleteImage(id: string): Promise<void> {
        return this.client.makeRequest<void>({
            path: "images/" + id,
            method: "DELETE",
        });
    }

    async createProduct(params: ICreateProductParams): Promise<Product> {
        return this.client.makeRequest<Product>({
            path: "products",
            method: "POST",
            body: params,
        });
    }

    async deleteProduct(id: string): Promise<void> {
        return this.client.makeRequest<void>({
            path: "products/" + id,
            method: "DELETE",
        });
    }

    async editProduct(params: IEditProductParams): Promise<Product> {
        return this.client.makeRequest<Product>({
            path: "products/" + params.id,
            method: "PATCH",
            body: params,
        });
    }

    async getFeaturedCategories(): Promise<FeaturedCategory[]> {
        return this.client.makeRequest<FeaturedCategory[]>({
            path: "categories/featured",
            method: "GET",
        });
    }

    async getBestSellers(): Promise<Product[]> {
        return this.client.makeRequest<Product[]>({
            path: "products?featureId=67751eeb8ba13e807ee0ab80",
            method: "GET",
        });
    }

    async getProductRanges(): Promise<ProductRange[]> {
        return await this.client.makeRequest<ProductRange[]>({
            path: "product-ranges",
            method: "GET"
        });
    }

    async createProductRange(name: string): Promise<ProductRange> {
        return this.client.makeRequest<ProductRange>({
            path: "product-ranges",
            method: "POST",
            body: {
                name: name,
            }
        });
    }

    async deleteProductRange(id: string): Promise<void> {
        return this.client.makeRequest<void>({
            path: "product-ranges/" + id,
            method: "DELETE",
        });
    }

    async getProductsByRange(): Promise<ProductsByRange[]>{
        return this.client.makeRequest<ProductsByRange[]>({
            path: "products/ranges",
            method: "GET",
        });
    }

    async getProductById(id: string): Promise<Product> {
        return this.client.makeRequest<Product>({
            path: "products/" + id,
            method: "GET"
        });
    }

}

export const apiClient = new ApiClient();