import React, { useEffect, useState } from "react"
import { productDetailsStore } from "../../data/stores/product_details_store.tsx"
import { useParams } from "react-router-dom"
import { ProductDetailsHeaderSection, ProductDetailsHeaderSectionLoader } from "./components/product_details_header_section.tsx"
import { Box, Button, Grid2, Skeleton, Typography } from "@mui/material"
import { apiClient } from "../../data/network/api_client.ts"
import { Carousel } from "../../components/organisms/carousel.tsx"
import { relatedProductsStore } from "../../data/stores/related_products_store.tsx"
import { ProductRange } from "../../data/entities/product_range.ts"
import { ProductsGrid } from '../../components/organisms/products_grid.tsx';

type RelatedProductsProps = {
    range?: ProductRange,
}

const RelatedProducts: React.FC<RelatedProductsProps> = (
    props: RelatedProductsProps,
) => {
    const {
        range
    } = props;
    const { products, load } = relatedProductsStore();


    useEffect(
        () => {
            if (range) {
                load(range);
            }
        },
        [load, range],
    )

    if (!range) {
        return <></>;
    }


    return products.when(
        (data) => {
            if (!data.length) {
                return <></>;
            }

            return <Box
                padding={{
                    xs: "0 48px",
                    sm: "0 48px",
                    md: '0px 96px',
                    lg: '0px 140px',
                    xl: '0px 140px',
                }}
            >
                <Typography
                    color="text.primary"
                    align="center"
                    fontSize={{
                        xs: 22,
                        sm: 22,
                        md: 28,
                        lg: 32,
                        xl: 32,
                    }}
                    marginTop={{
                        xs: 8,
                        sm: 8,
                        md: 12,
                        lg: 20,
                        xl: 20,
                    }}
                    marginBottom={{
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                        xl: 5,
                    }}
                >
                    Related products
                </Typography>
                <ProductsGrid
                    products={data}
                    marginOverride="0px"
                />
            </Box>
        },
        (_) => <></>,
        () => <></>,
    );
}

export const ProductDetails: React.FC = () => {
    const { product, load } = productDetailsStore();
    const { id } = useParams();
    const [selectedQuantity, setSelectedQuantity] = useState<number>(1);

    useEffect(
        () => {
            load(id as string);
        },
        [load, id],
    )

    return product.when(
        (data) => {
            return <>
                <Grid2
                    columns={{
                        xs: 1,
                        sm: 1,
                        md: 7,
                        lg: 7,
                        xl: 7,
                    }}
                    container
                    spacing={{
                        xs: '28px',
                        sm: '28px',
                        md: '16px',
                        lg: '24px',
                        xl: '24px',
                    }}
                    margin={{
                        xs: 0,
                        sm: 0,
                        md: '0px 96px',
                        lg: '0px 140px',
                        xl: '0px 140x',
                    }}
                    marginTop={{
                        xs: '16px',
                        sm: '16px',
                        md: '28px',
                        lg: '48px',
                        xl: '48px',
                    }}
                >
                    <Grid2
                        size={1}
                        flexGrow={1}
                        height='100%'
                    >
                        <Carousel
                            positionIndicatorInternally={true}
                            items={
                                data.images.map(
                                    (el) => <Box
                                        display="flex"
                                        justifyContent='center'
                                        height={{
                                            xs: '250px',
                                            sm: '250px',
                                            md: '380px',
                                            lg: '735px',
                                            xl: '735px',
                                        }}
                                    >
                                        <Box
                                            margin={{
                                                xs: '0px 48px',
                                                sm: '0px 48px',
                                                md: "0px",
                                                lg: 0,
                                                xl: 0,
                                            }}
                                            overflow='hidden'
                                            borderRadius='16px'
                                        >
                                            <img
                                                src={apiClient.makeImageUrl(el.path)}
                                                alt=""
                                                width='100%'
                                                height='100%'
                                                style={{
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )
                            }
                        />
                    </Grid2>
                    <Grid2
                        size={3}
                        container
                        flexDirection='column'
                        justifyContent="center"
                        sx={{
                            maxHeight: {
                                xs: '250px',
                                sm: '250px',
                                md: '380px',
                                lg: '735px',
                                xl: '735px',
                            },
                        }}
                    >
                        <Box
                            width='100%'
                        >
                            <ProductDetailsHeaderSection
                                title={data.title}
                                category={data.category.localisedValue}
                                price={data.price}
                                quantityAvailable={10}
                                selectedQuantity={selectedQuantity}
                                onQuantityChange={setSelectedQuantity}
                            />
                        </Box>
                    </Grid2>
                </Grid2>
                <Grid2
                    columns={{
                        xs: 1,
                        sm: 1,
                        md: 7,
                        lg: 7,
                        xl: 7,
                    }}
                    container
                    spacing={6}
                    margin={{
                        xs: 0,
                        sm: 0,
                        md: '0px 96px',
                        lg: '0px 140px',
                        xl: '0px 140px',
                    }}
                >
                    <Grid2
                        size={1}
                        flexGrow={1}
                        margin={{
                            xs: '48px 0px',
                            sm: '48px 0px',
                            md: '40px 0px',
                            lg: '40px 0px',
                            xl: '40px 0px',
                        }}
                        padding={{
                            xs: '0px 48px',
                            sm: '0px 48px',
                            md: '0px',
                            lg: '0px',
                            xl: '0px',
                        }}
                    >
                        <Typography
                            fontSize={{
                                xs: '16px',
                                sm: '16px',
                                md: '20px',
                                lg: '20px',
                                xl: '20px',
                            }}
                            color="primary.main"
                            lineHeight='28px'
                            whiteSpace='pre-line'
                            fontWeight='300'
                        >
                            {data.description}
                        </Typography>
                    </Grid2>
                    <Grid2
                        size={3}
                    />
                </Grid2>
                <RelatedProducts
                    range={data.range}
                />
                <Box
                    height={4}
                />
            </>
        },
        (error) => {
            return <Box
                marginTop={{
                    xs: '48px',
                    sm: '48px',
                    md: '96px',
                    lg: '140px',
                    xl: '140px',
                }}
                width='100%'
                justifyContent='center'
                textAlign='center'
            >
                <Box
                    marginBottom="24px"
                >
                    <Typography
                        align="center"
                    >
                        Something went wrong
                    </Typography>
                </Box>
                <Button
                    variant='filled'
                    onClick={() => load(id as string)}
                >
                    Retry
                </Button>
            </Box>
        },
        () => {
            return <Grid2
                columns={{
                    xs: 1,
                    sm: 1,
                    md: 7,
                    lg: 7,
                    xl: 7,
                }}
                container
                spacing={6}
                margin={{
                    xs: 0,
                    sm: 0,
                    md: '0px 96px',
                    lg: '0px 140px',
                    xl: '0px 140x',
                }}
                marginTop={{
                    xs: '16px',
                    sm: '16px',
                    md: '28px',
                    lg: '48px',
                    xl: '48px',
                }}
            >
                <Grid2
                    size={1}
                    flexGrow={1}
                >
                    <Skeleton
                        sx={{
                            height: {
                                xs: '250px',
                                sm: '250px',
                                md: '380px',
                                lg: '735px',
                                xl: '735px',
                            },
                            margin: {
                                xs: '0px 48px',
                                sm: '0px 48px',
                                md: "0px",
                                lg: 0,
                                xl: 0,
                            },
                            borderRadius: '16px'
                        }}
                    />
                </Grid2>
                <Grid2
                    size={3}
                    container
                    flexDirection='column'
                    justifyContent="center"
                    sx={{
                        maxHeight: {
                            xs: '250px',
                            sm: '250px',
                            md: '380px',
                            lg: '735px',
                            xl: '735px',
                        },
                    }}
                >
                    <Box
                        width='100%'
                    >
                        <ProductDetailsHeaderSectionLoader />
                    </Box>
                </Grid2>
            </Grid2>
        }
    );
}