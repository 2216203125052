import React, { useEffect } from "react";
import { bestSellersStore } from '../../../data/stores/best_sellers_store.tsx';
import { Grid2, Box, Skeleton } from "@mui/material";
import { HomeSectionHeading, HomeSectionHeadingLoader } from "./home_section_heading.tsx";
import { ProductsGrid } from "../../../components/organisms/products_grid.tsx";

const Loader: React.FC = () => {
    return <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
    >
        <HomeSectionHeadingLoader />
        <Grid2
            container
            spacing="16px"
            columns={{
                xs: 2,
                md: 3,
                lg: 4,
                xl: 4,
            }}
            padding={{
                xs: "0px 48px",
                sm: "0px 48px",
                md: "0px 82px",
                lg: "0px 124px",
                xl: "0px 200px",
            }}
            width='100%'
        >
            {
                Array.from(Array(8)).map(
                    (el, i) => {
                        return <Grid2
                            key={'load_item_' + i}
                            size={1}
                        >
                            <Skeleton
                                key={'loader_' + i}
                                sx={{
                                    width: '100%',
                                    height: {
                                        xs: "134px",
                                        sm: "134px",
                                        md: "160px",
                                        lg: "194px",
                                        xl: "194px",
                                    }
                                }}
                            />
                            <Skeleton
                                sx={{
                                    lineHeight: '1.2',
                                    width: '80%',
                                    marginTop: {
                                        xs: 1,
                                        md: 2,
                                        lg: 2,
                                        xl: 2,
                                    }
                                }}
                            />
                            <Skeleton
                                sx={{
                                    lineHeight: '1.2',
                                    width: '40px',
                                    marginTop: {
                                        xs: 1,
                                        md: 2,
                                        lg: 2,
                                        xl: 2,
                                    }
                                }}
                            />
                        </Grid2>
                    }
                )
            }
        </Grid2>
    </Box >;
}

export const HomeBestSellersGrid: React.FC = () => {
    const { products, load } = bestSellersStore();

    useEffect(
        () => {
            load();
        },
        [load],
    )

    return products.when(
        (data) => {
            return <Box>
                <HomeSectionHeading>
                    Best sellers
                </HomeSectionHeading>
                <ProductsGrid
                    products={data.slice(0, 8)}
                />
            </Box>
        },
        (error) => {
            return <></>;
        },
        () => <Loader />
    )
}