import React, { PropsWithChildren } from 'react';
import { useAuth } from './auth_provider.tsx';
import { Login } from '../auth/login.tsx';
import { Navigate } from 'react-router-dom'

type AuthedRouteProps = PropsWithChildren;

export const AuthedRoute: React.FC<AuthedRouteProps> = (props: AuthedRouteProps) => {
    const { user, handleLogin } = useAuth();
    if (!user) {
        return (
            <Login
                handleLogin={handleLogin}
            />
        );
    } else if (user?.role !== "admin") {
        // Go home if user is not authenticated
        return (
            <Navigate to="/" />
        );
    }

    return props.children;
};
