import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import React from "react";
import { Spacer } from "../../../components/atoms/spacer.tsx";
import { CreateProductCategorySelector } from "./create_product_category_selector.tsx";
import { CreateProductFeatureSelector } from "./create_product_feature_selector.tsx";
import { CreateProductRangeSelector } from "./create_product_range_selector.tsx";

type ManageProductFormProms = {
    title: string | undefined,
    setTitle: (value: string) => void,
    description: string | undefined,
    setDescription: (value: string) => void,
    quantity: number | undefined,
    setQuantity: (value: number) => void,
    price: number | undefined,
    setPrice: (value: number) => void,
    categoryId: string | undefined,
    setCategoryId: (value: string) => void,
    featureIds: string[],
    setFeatureIds: (value: string[]) => void,
    rangeId: string | undefined,
    setRangeId: (value: string) => void,
}

export const ManageProductForm: React.FC<ManageProductFormProms> = (
    props: ManageProductFormProms,
) => {
    const {
        title,
        setTitle,
        description,
        setDescription,
        quantity,
        setQuantity,
        price,
        setPrice,
        categoryId,
        setCategoryId,
        featureIds,
        setFeatureIds,
        rangeId,
        setRangeId,
    } = props;

    return <FormControl>
        <TextField
            label="Title"
            onChange={(v) => {
                setTitle(v.target.value);
            }}
            type="text"
            value={title ?? ''}
        />
        <Spacer axis="vertical" pixels={8} />
        <TextField
            label="Description"
            multiline
            onChange={(v) => {
                setDescription(v.target.value);
            }}
            type="text"
            value={description ?? ''}
        />
        <Spacer axis="vertical" pixels={8} />
        <div
            style={{
                display: "flex",
            }}
        >
            <TextField
                label="Quantity"
                onChange={(v) => {
                    setQuantity(Number.parseInt(v.target.value));
                }}
                type="number"
                value={quantity ?? ''}
            />
            <Spacer axis="horizontal" pixels={8} />
            <FormControl>
                <InputLabel htmlFor="price-label">Price</InputLabel>
                <OutlinedInput
                    id="price-label"
                    startAdornment={<InputAdornment position="start">R</InputAdornment>}
                    label="Price"
                    type="number"
                    onChange={(v) => {
                        setPrice(Number.parseInt(v.target.value));
                    }}
                    value={price ?? ''}
                />
            </FormControl>
        </div>
        <Spacer axis="vertical" pixels={8} />
        <CreateProductCategorySelector
            selectedCategoryId={categoryId}
            onSelect={(v) => {
                setCategoryId(v);
            }}
        />
        <Spacer axis="vertical" pixels={8} />
        <CreateProductFeatureSelector
            selectedFeatureIds={featureIds}
            onSelect={(v: string[]) => {
                setFeatureIds(v);
            }}
        />
        <Spacer axis="vertical" pixels={8} />
        <CreateProductRangeSelector
            selectedRangeId={rangeId}
            onSelect={(v: string) => {
                setRangeId(v);
            }}
        />
    </FormControl>
}