import { Box, Button, Skeleton, Typography } from "@mui/material";
import React from "react"
import { NumberPicker } from "../../../components/molecules/number_picker.tsx";

type ProductDetailsHeaderSectionProps = {
    title: string,
    category: string,
    price: number,
    quantityAvailable: number,
    selectedQuantity: number,
    onQuantityChange: (number) => void,
};

export const ProductDetailsHeaderSectionLoader: React.FC = () => {
    return <Box
        margin='0px 48px'
        height='100%'
    >
        <Box
            marginBottom={{
                xs: '4px',
                sm: '4px',
                md: '8px',
                lg: '8px',
                xl: '8px',
            }}
        >
            <Skeleton
                sx={{
                    height: {
                        xs: '22px',
                        sm: '22px',
                        md: '28px',
                        lg: '40px',
                        xl: '40px',
                    },
                }}
            />
        </Box>
        <Skeleton
            sx={{
                height: {
                    xs: '16px',
                    sm: '16px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                },
            }}
        />
        <Box
            margin={{
                xs: '16px 0px',
                sm: '16px 0px',
                md: '16px 0px',
                lg: '20px 0px',
                xl: '20px 0px',
            }}
        >
            <Skeleton
                sx={{
                    height: {
                        xs: '22px',
                        sm: '22px',
                        md: '26px',
                        lg: '34px',
                        xl: '34px',
                    },
                }}
            />
        </Box>
        <Skeleton
            sx={{
                height: {
                    xs: '16px',
                    sm: '16px',
                    md: '20px',
                    lg: '24px',
                    xl: '24px',
                },
            }}
        />
    </Box>
}

export const ProductDetailsHeaderSection: React.FC<ProductDetailsHeaderSectionProps> = (
    props: ProductDetailsHeaderSectionProps,
) => {

    const {
        title,
        category,
        price,
        quantityAvailable,
        selectedQuantity,
        onQuantityChange,
    } = props;

    return <Box
        margin='0px 48px'
        height='100%'
    >
        <Box
            marginBottom={{
                xs: '4px',
                sm: '4px',
                md: '8px',
                lg: '8px',
                xl: '8px',
            }}
        >
            <Typography
                fontSize={{
                    xs: '22px',
                    sm: '22px',
                    md: '28px',
                    lg: '40px',
                    xl: '40px',
                }}
                color="primary.main"
                lineHeight={1}
            >
                {title}
            </Typography>
        </Box>
        <Typography
            fontSize={{
                xs: '16px',
                sm: '16px',
                md: '20px',
                lg: '24px',
                xl: '24px',
            }}
            color="primary.main"
            lineHeight={1}
            fontWeight='300'
        >
            {category}
        </Typography>
        <Box
            margin={{
                xs: '16px 0px',
                sm: '16px 0px',
                md: '16px 0px',
                lg: '20px 0px',
                xl: '20px 0px',
            }}
        >
            <Typography
                fontSize={{
                    xs: '22px',
                    sm: '22px',
                    md: '26px',
                    lg: '34px',
                    xl: '34px',
                }}
                color="primary.main"
                lineHeight={1}
                fontWeight='500'
            >
                {"R" + price}
            </Typography>
        </Box>
        <Typography
            fontSize={{
                xs: '16px',
                sm: '16px',
                md: '20px',
                lg: '24px',
                xl: '24px',
            }}
            color="primary.main"
            fontWeight='300'
            lineHeight={1}
        >
            {"Quantity available: " + quantityAvailable}
        </Typography>
        {/* <Typography
            fontSize={{
                xs: '16px',
                sm: '16px',
                md: '20px',
                lg: '24px',
                xl: '24px',
            }}
            color="primary.main"
            fontWeight='300'
            lineHeight={1}
        >
            Quantity
        </Typography>
        <Box
            margin={{
                xs: '16px 0px',
                sm: '16px 0px',
                md: '16px 0px',
                lg: '20px 0px',
                xl: '20px 0px',
            }}
        >
            <NumberPicker
                currentValue={selectedQuantity}
                onChange={onQuantityChange}
                max={quantityAvailable}
            />
        </Box>
        <Button
            variant="filled"
        >
            Add to cart
        </Button> */}
    </Box>
}