import { create } from 'zustand'
import { SearchParams } from '../entities/search_params.ts';
import { Category } from '../entities/category.ts';
import { ProductFeature } from '../entities/product_feature.ts';
import { ProductRange } from '../entities/product_range.ts';

type Filters = {
    category?: Category,
    feature?: ProductFeature,
    range?: ProductRange,
    clear?: boolean,
}

type State = {
    params: SearchParams,
}

type Actions = {
    setKeywords: (query: string | undefined) => void,
    setFilter: (
        filters: Filters,
        clear?: boolean,
    ) => void,
    clearFilters: () => void,
}

export const searchBuilder = create<State & Actions>((set, state) => ({
    params: {},
    setKeywords: (keywords: string | undefined) => {
        set(
            () => ({
                params: {
                    keywords: keywords,
                },
            })
        );
    },
    setFilter: (filters: Filters, clear?: boolean) => {
        var params: SearchParams = state().params;
        if(clear === true){
            params = {};
        }

        if (clear) {
            params = {};
        }
        if (filters.category) {
            params.category = filters.category;
        }
        if (filters.feature) {
            params.feature = filters.feature;
        }
        if (filters.range) {
            params.range = filters.range;
        }
        set(
            () => ({
                params: params,
            })
        );
    },
    clearFilters: () => {
        set(
            () => ({
                params: {}
            })
        );
    }
}))