import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';


type AppColours = {
  primary: {
    main: string,
    secondary: string,
    light: string,
  },
  text: {
    primary: string,
    secondary: string,
    disabled: string,
  },
  greys: {
    dark: string,
    medium: string,
    light: string,
    accent: string,
  },
  white: string,
}

export const appColors: AppColours = {
  primary: {
    main: "#EF8D8C",
    secondary: "#B3745D",
    light: "#FDE3E4",
  },
  text: {
    primary: "#EF8D8C",
    secondary: "#B3745D",
    disabled: "#878787",
  },
  greys: {
    dark: "#B3B3B3",
    medium: "#B3B3B3",
    light: "#D9D9D9",
    accent: "#EEEEEE",
  },
  white: "#FFF",
}

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: appColors.primary,
    text: appColors.text,
  },
  spacing: 4,
  typography: {
    fontFamily: "Readex+Pro",
    button: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: '0.03em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: 'none',
          padding: "8px 16px",
          fontWeight: '600'
        }
      },
      variants: [
        {
          props: {
            variant: 'filled',
          },
          style: {
            textTransform: 'none',
            backgroundColor: appColors.primary.light,
            color: "#EF8D8C",
          },
        },
        {
          props: {
            variant: 'contained',
          },
          style: {
            textTransform: 'none',
            backgroundColor: 'white',
            borderRadius: "16px",
            boxShadow: 'none',
            padding: "8px 16px",
            color: "#EF8D8C",
            fontWeight: '600',
          },
        },
      ]
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        root: {
          transform: "unset",
        },
      },
    },
  }
};

export const appTheme = createTheme(
  themeOptions,
);

export const responsiveAppTheme = responsiveFontSizes(appTheme);

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    filled: true;
  }
}
