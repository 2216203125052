import React from "react"
import { Product } from "../../data/entities/product.ts"
import { Box, Typography } from "@mui/material";
import { apiClient } from "../../data/network/api_client.ts";
import { useNavigate } from "react-router-dom";

type ProductCardProps = {
    product: Product,
}

export const ProductCard: React.FC<ProductCardProps> = (
    props: ProductCardProps,
) => {
    const {
        product
    } = props;

    const navigate = useNavigate()

    return <Box
        onClick={() => {
            navigate("/product/" + product._id);
        }}
    >
        <Box
            height={{
                xs: "130px",
                sm: "130px",
                md: "220px",
                lg: "300px",
                xl: "300px",
            }}
            overflow='hidden'
            borderRadius={{
                xs: "16px",
                sm: "16px",
                md: "20px",
                lg: "20px",
                xl: "20px",
            }}
        >
            <img
                src={apiClient.makeImageUrl(product.images[0].path)}
                alt=""
                width="100%"
                height="100%"
                style={{
                    objectFit: 'cover'
                }}
            />
        </Box>
        <Typography
            variant="body2"
            color="primary.main"
            lineHeight={1}
            marginTop={{
                xs: 2,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 4,
            }}
            fontSize={{
                xs: 14,
                sm: 14,
                md: 20,
                lg: 28,
                xl: 28,
            }}
        >
            {product.title}
        </Typography>
        <Typography
            variant="caption"
            color="text.secondary"
            lineHeight={1.5}
            fontSize={{
                xs: 12,
                sm: 12,
                md: 16,
                lg: 22,
                xl: 22,
            }}
        >
            {"R " + product.price}
        </Typography>
    </Box>
}