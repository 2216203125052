import { DialogContent, DialogActions, DialogTitle, Button, Dialog, Box, ImageList, ImageListItem, IconButton, ImageListItemBar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Image } from "../../../data/entities/image.ts";
import { ImageSelectorDrawer } from "./image_selector_drawer.tsx";
import { apiClient } from "../../../data/network/api_client.ts";
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { Spacer } from "../../../components/atoms/spacer.tsx";
import { ManageProductForm } from './manage_product_form.tsx';
import { ICreateProductParams } from "../../../data/states/product_management_store.ts";
import { StringUtils } from '../../../utils/string_utils.ts';
import { Delete } from "@mui/icons-material";
import { Product } from "../../../data/entities/product.ts";

type CreateProductModalProps = {
    onSubmit: (value: ICreateProductParams) => void;
    onClose: () => void,
    visible: boolean,
    productToEdit?: Product,
    duplicate?: Product,
}

export const CreateProductModal: React.FC<CreateProductModalProps> = (props: CreateProductModalProps) => {
    const {
        onSubmit,
        onClose,
        visible,
        productToEdit,
        duplicate,
    } = props;

    const [showImageDrawer, setShowImageDrawer] = useState<boolean>(false);

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [quantity, setQuantity] = useState<number>();
    const [price, setPrice] = useState<number>();
    const [images, setImages] = useState<Image[]>([]);
    const [categoryId, setCategoryId] = useState<string>();
    const [featureIds, setFeatureIds] = useState<string[]>([]);
    const [rangeId, setRangeId] = useState<string>();

    useEffect(
        () => {
            if (productToEdit) {
                setTitle(productToEdit.title);
                setDescription(productToEdit.description);
                setQuantity(productToEdit.quantity);
                setPrice(productToEdit.price);
                setImages(productToEdit.images);
                setCategoryId(productToEdit.category._id);
                setFeatureIds(productToEdit.features.map((el) => el._id));
                setRangeId(productToEdit.range?._id);
            } else if (duplicate) {
                setTitle(duplicate.title);
                setDescription(duplicate.description);
                setQuantity(duplicate.quantity);
                setPrice(duplicate.price);
                setImages(duplicate.images);
                setCategoryId(duplicate.category._id);
                setFeatureIds(duplicate.features.map((el) => el._id));
                setRangeId(duplicate.range?._id);
            }
        },
        [productToEdit, duplicate],
    )

    const handleImageSelect = (image: Image) => {
        const existing: Image[] = images;

        if (existing.some(el => el._id === image._id)) {
            setImages(existing.filter(el => el._id !== image._id))
        } else {
            setImages([...existing, image]);
        }
    }

    const clearState = () => {
        setTitle(undefined);
        setDescription(undefined);
        setQuantity(undefined);
        setPrice(undefined);
        setImages([]);
        setCategoryId(undefined);
        setFeatureIds([]);
    }

    const handleSubmit = () => {
        const data = {
            title: title!,
            description: description!,
            quantity: quantity!,
            price: price!,
            imageIds: images.map((item) => item._id),
            categoryId: categoryId!,
            featureIds: featureIds,
            rangeId: rangeId,
        }
        if (productToEdit) {
            data["id"] = productToEdit._id;
        }
        onSubmit(data)
        clearState();
    }

    const handleClose = () => {
        onClose();
        clearState();
    }

    return <>
        <Dialog
            open={visible}
            onClose={handleClose}
        >
            <DialogContent>
                <DialogTitle>
                    Create product
                </DialogTitle>
                <Box>
                    {
                        images.length ?
                            <ImageList
                                cols={2}
                                rowHeight={160}
                            >
                                {images.map((item) => (
                                    <ImageListItem
                                        key={item._id}
                                    >
                                        <img
                                            src={apiClient.makeImageUrl(item.path)}
                                            alt={""}
                                            loading="lazy"
                                            style={{
                                                height: 160,
                                                objectFit: "contain"
                                            }}
                                        />
                                        <ImageListItemBar
                                            title={
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {StringUtils.stripExt(item.path)}
                                                </Typography>
                                            }
                                            actionIcon={
                                                <IconButton
                                                    onClick={() => {
                                                        handleImageSelect(item)
                                                    }}
                                                >
                                                    <Delete
                                                        color="error"
                                                    />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList> :
                            <div
                                style={{
                                    margin: "32px"
                                }}
                            >
                                <Align
                                    alignment={Alignment.center}
                                >
                                    <Button
                                        onClick={() => {
                                            setShowImageDrawer(true);
                                        }}
                                    >
                                        Add image
                                    </Button>
                                </Align>
                            </div>
                    }
                    <Spacer axis="vertical" pixels={4} />
                </Box>
                <Spacer axis="vertical" pixels={4} />
                <ManageProductForm
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    price={price}
                    setPrice={setPrice}
                    categoryId={categoryId}
                    setCategoryId={setCategoryId}
                    featureIds={featureIds}
                    setFeatureIds={setFeatureIds}
                    rangeId={rangeId}
                    setRangeId={setRangeId}

                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <ImageSelectorDrawer
            visible={showImageDrawer}
            onClose={() => {
                setShowImageDrawer(false);
            }}
            onSelect={(image: Image) => {
                handleImageSelect(image);
            }}
            selectedIds={images.map((el) => el._id)}
        />
    </>;
}