import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/auth_provider.tsx';
import { Login } from '../auth/login.tsx';
import { Navigate } from 'react-router-dom'
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from '../../components/molecules/tab_panel.tsx';
import { categoryManagementStore } from '../../data/states/category_management_store.ts';
import { productManagementStore } from '../../data/states/product_management_store.ts';
import { productFeatureManagementStore } from '../../data/states/product_feature_management_store.ts';
import { imageManagementStore } from '../../data/states/image_management_store.ts';
import { productRangeManagementStore } from '../../data/states/product_range_management_store.ts';
import { ProductTab } from './product_tab.tsx';
import { CategoryTab } from './category_tab.tsx';
import { ImageTab } from './image_tab.tsx';
import { ProductFeatureTab } from './product_feature_tab.tsx';
import { ProductRangeTab } from './product_range_tab.tsx';

export const Management: React.FC = () => {
    const { user, handleLogin } = useAuth();
    const [activeTab, setTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    const { getCategories } = categoryManagementStore();
    const { getProducts } = productManagementStore();
    const { getProductFeatures } = productFeatureManagementStore();
    const { getImages } = imageManagementStore();
    const { getProductRanges } = productRangeManagementStore();

    useEffect(
        () => {
            if (user?.role === "admin") {
                getProducts();
                getCategories();
                getImages();
                getProductFeatures();
                getProductRanges();
            }
        },
        [
            user,
            getProducts,
            getCategories,
            getImages,
            getProductFeatures,
            getProductRanges,
        ],
    );

    if (!user) {
        return (
            <Login
                handleLogin={handleLogin}
            />
        );
    } else if (user?.role !== "admin") {
        // Go home if user is not authenticated
        return (
            <Navigate to="/" />
        );
    }

    return <>
        <Box
            sx={{
                borderBottom: 1,
                borderColor: 'divider',
            }}
        >
            <Tabs
                value={activeTab}
                variant="fullWidth"
                onChange={handleChange}
                role="navigation"
            >
                <Tab
                    label={
                        <Typography variant="body2">
                            Products
                        </Typography>
                    }
                    value={0}
                />
                <Tab
                    label={
                        <Typography variant="body2">
                            Categories
                        </Typography>
                    }
                    value={1}
                />
                <Tab
                    label={
                        <Typography variant="body2">
                            Images
                        </Typography>
                    }
                    value={2}
                />
                <Tab
                    label={
                        <Typography variant="body2">
                            Features
                        </Typography>
                    }
                    value={3}
                />
                <Tab
                    label={
                        <Typography variant="body2">
                            Ranges
                        </Typography>
                    }
                    value={4}
                />
            </Tabs>
        </Box>
        <TabPanel
            value={activeTab}
            index={0}
        >
            <ProductTab />
        </TabPanel>
        <TabPanel
            value={activeTab}
            index={1}
        >
            <CategoryTab />
        </TabPanel>
        <TabPanel
            value={activeTab}
            index={2}
        >
            <ImageTab />
        </TabPanel>
        <TabPanel
            value={activeTab}
            index={3}
        >
            <ProductFeatureTab />
        </TabPanel>
        <TabPanel
            value={activeTab}
            index={4}
        >
            <ProductRangeTab />
        </TabPanel>
    </>
};
