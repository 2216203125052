import React, { useEffect, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { Align, Alignment } from "../../../components/atoms/align.tsx";
import { Product } from "../../../data/entities/product.ts";
import { ManagementProductCard } from "./management_product_card.tsx";
import { ManagementProductsFilters } from "./management_products_filters.tsx";
import { DeleteProductConfirmationModal } from "./delete_product_confirmation_modal.tsx";
import { Spacer } from "../../../components/atoms/spacer.tsx";

type ProductListProps = {
    products: Product[],
    deleteProduct: (product: Product) => Promise<void>,
    editProduct: (product: Product) => void,
    duplicateProduct: (product: Product) => void,
}

type ProductsFiltersParams = {
    categoryId: string,
    title: string | undefined,
    featureId: string,
    rangeId: string,
}

export const ProductList: React.FC<ProductListProps> = (props: ProductListProps) => {
    const {
        products,
        deleteProduct,
        editProduct,
        duplicateProduct,
    } = props;

    const [filtered, setFiltered] = useState<Product[]>(products);
    const [product, setProduct] = useState<Product>();
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [filters, setFilters] = useState<ProductsFiltersParams>({
        categoryId: 'all',
        title: undefined,
        featureId: 'all',
        rangeId: 'all',
    });

    useEffect(
        () => {
            const handleFilter = () => {
                const matches = products.filter((el) => {
                    const catMatch: boolean = filters.categoryId !== 'all'
                        ? el.category._id === filters.categoryId
                        : true;

                    const titleMatch: boolean = filters.title
                        ? el.title.toLowerCase().includes(filters.title.toLowerCase())
                        : true;

                    const featureMatch: boolean = filters.featureId !== 'all'
                        ? el.features.some((el) => el._id === filters.featureId)
                        : true;

                    var rangeMatch: boolean = true;
                    if (filters.rangeId !== "all") {
                        if (!el.range) {
                            rangeMatch = false;
                        } else {
                            rangeMatch = el.range!._id === filters.rangeId;
                        }
                    }

                    return catMatch && titleMatch && featureMatch && rangeMatch;
                });

                setFiltered(matches);
            }
            handleFilter();
        },
        [filters, products],
    )

    return <>
        {
            product ?
                <DeleteProductConfirmationModal
                    product={product}
                    onCancel={() => {
                        setDeleteModalVisible(false)
                    }}
                    onConfirm={() => {
                        deleteProduct(product);
                        setDeleteModalVisible(false);
                    }}
                    visible={deleteModalVisible}
                />
                :
                <></>
        }

        <Box
            width={"90vw"}
            sx={{
                padding: "24px",
                justifyContent: 'center',
            }}
        >
            <Typography>Filter by:</Typography>
            <Spacer
                axis="vertical"
                pixels={16}
            />
            <ManagementProductsFilters
                selectedCategoryId={filters.categoryId}
                onCategorySelect={(value: string) => {
                    setFilters({
                        categoryId: value,
                        title: filters.title,
                        featureId: filters.featureId,
                        rangeId: filters.rangeId,
                    });
                }}
                selectedTitle={filters.title ?? ''}
                onTitleSelect={(value: string) => {
                    setFilters({
                        categoryId: filters.categoryId,
                        title: value,
                        featureId: filters.featureId,
                        rangeId: filters.rangeId,
                    });
                }}
                selectedFeatureId={filters.featureId}
                onFeatureIdSelect={(value: string) => {
                    setFilters({
                        categoryId: filters.categoryId,
                        title: filters.title,
                        featureId: value,
                        rangeId: filters.rangeId,
                    });
                }}
                selectedRangeId={filters.rangeId}
                onRangeIdSelect={(value: string) => {
                    setFilters({
                        categoryId: filters.categoryId,
                        title: filters.title,
                        featureId: filters.featureId,
                        rangeId: value,
                    });
                }}
            />
        </Box>
        {
            !filtered.length ?
                <Align
                    alignment={Alignment.center}
                >
                    <Typography
                        variant="subtitle1"
                        align='center'
                    >
                        No products
                    </Typography>
                </Align> :
                <Grid2
                    container
                    columns={20}
                    spacing={2}
                    sx={{
                        margin: "0px 32px",
                        paddingBottom: "32px",
                    }}
                >
                    {
                        filtered.map(
                            (el) =>
                                <Grid2 size={4} key={el._id}>
                                    <ManagementProductCard
                                        key={el._id}
                                        product={el}
                                        onEdit={() => {
                                            editProduct(el);
                                        }}
                                        onDuplicate={() => {
                                            duplicateProduct(el);
                                        }}
                                        onDelete={() => {
                                            setProduct(el);
                                            setDeleteModalVisible(true);
                                        }}
                                    />
                                </Grid2>
                        )
                    }
                </Grid2>
        }
    </>
}