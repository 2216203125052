import React, { useState } from 'react';
import { productFeatureManagementStore } from '../../data/states/product_feature_management_store.ts';
import { ProductFeature } from '../../data/entities/product_feature.ts';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Align, Alignment } from '../../components/atoms/align.tsx';
import { FloatingActionButton } from '../../components/atoms/floating_action_button.tsx';
import { Add } from '@mui/icons-material';
import { LocalisedCanonicalList } from './components/localised_canonical_list.tsx';
import { CreateProductFacetModal } from './components/create_product_facet_model.tsx';

export const ProductFeatureTab: React.FC = () => {
    const { productFeatures, createProductFeature, deleteProductFeature, retry } = productFeatureManagementStore(
        (state) => state,
    );
    const [modalIsVisible, setModalIsVisible] = useState(false);

    return productFeatures.when(
        (data: ProductFeature[]) => {
            return <>
                <Align
                    alignment={Alignment.top}
                >
                    <LocalisedCanonicalList
                        title="Product features"
                        items={data}
                        deleteItem={async (feature) => {
                            await deleteProductFeature(feature._id);
                        }}
                    />
                </Align>
                <CreateProductFacetModal
                    facetName='Product feature'
                    visible={modalIsVisible}
                    onSubmit={(value: string) => {
                        createProductFeature(value).then(
                            (v) => {
                                setModalIsVisible(false);
                            }
                        ).catch(
                            (e) => {
                                alert(e);
                            }
                        );
                    }}
                    onClose={() => {
                        setModalIsVisible(false);
                    }}
                />
                <FloatingActionButton
                    onClick={() => {
                        setModalIsVisible(true);
                    }}
                >
                    <Add />
                </FloatingActionButton>
            </>;
        },
        (error: Error) => {
            return <Align
                alignment={Alignment.center}
            >
                <div>
                    <Typography
                        variant='h5'
                        sx={{
                            pb: "16px"
                        }}
                    >
                        Error fetching product features
                    </Typography>
                    <Align
                        alignment={Alignment.center}
                    >
                        <Button
                            variant='contained'
                            onClick={retry}
                            sx={{
                                width: "120px"
                            }}
                        >
                            RETRY
                        </Button>
                    </Align>
                </div>
            </Align >
        },
        () => {
            return <Align
                alignment={Alignment.center}
            >
                <CircularProgress />
            </Align>;
        },
    );
};