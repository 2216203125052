import { Box, Button } from "@mui/material";
import { FeaturedCategory } from "../../../data/entities/featured_category.tsx";
import { apiClient } from "../../../data/network/api_client.ts";
import React from "react";

type HomeFeaturedCategoryCardProps = {
    featuredCategory: FeaturedCategory,
    onSelect: () => void,
    radius: number,
};


export const HomeFeaturedCategoryCard: React.FC<HomeFeaturedCategoryCardProps> = (
    props: HomeFeaturedCategoryCardProps,
) => {
    const {
        featuredCategory,
        onSelect,
        radius,
    } = props;

    return <Box
        position="relative"
        borderRadius={radius}
        key={featuredCategory._id}
        height="100%"
        width="100%"
    >
        <img
            src={apiClient.makeImageUrl(featuredCategory.coverImage)}
            alt=""
            style={{
                width: '100%',
                height: '100%',
                borderRadius: radius,
                objectFit: 'cover',
            }}
        />
        <Button
            onClick={onSelect}
            variant='contained'
            sx={{
                position: "absolute",
                left: "16px",
                bottom: "16px",
            }}
        >
            {`View all ${featuredCategory.localisedValue}s`}
        </Button>
    </Box >;
}