import React from "react";
import { categoryManagementStore } from "../../../data/states/category_management_store.ts";
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";

type CreateProductCategorySelectorProps = {
    selectedCategoryId: string | undefined,
    onSelect: (selectedId: string) => void,
}


export const CreateProductCategorySelector: React.FC<CreateProductCategorySelectorProps> = (
    props: CreateProductCategorySelectorProps,
) => {
    const { categories, retry } = categoryManagementStore();

    const {
        selectedCategoryId,
        onSelect,
    } = props;

    return categories.when(
        (data) => {
            return <FormControl>
                <InputLabel id="category-selector-label">Category</InputLabel>
                <Select
                    labelId="category-selector-label"
                    value={selectedCategoryId ?? ''}
                    input={<OutlinedInput label="Category" />}
                    onChange={(v: SelectChangeEvent<string>) => {
                        onSelect(v.target.value)
                    }}
                >
                    {
                        data.map(
                            (item) => <MenuItem
                                key={item._id}
                                value={item._id}
                            >
                                {item.localisedValue}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        },
        (error) => {
            return <Button
                variant="outlined"
                onClick={retry}
            >
                RETRY CATEGORY LOAD
            </Button>;
        },
        () => {
            return <Typography>Loading categories</Typography>
        },
    )
}