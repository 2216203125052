import React from "react";
import { productRangeManagementStore } from "../../../data/states/product_range_management_store.ts";
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";

type ProductRangesProductsFilterProps = {
    selectedRangeId: string,
    onRangeSelect: (selectedId: string) => void,
}

export const ProductRangesProductsFilter: React.FC<ProductRangesProductsFilterProps> = (
    props: ProductRangesProductsFilterProps,
) => {
    const { productRanges, retry } = productRangeManagementStore();

    const {
        selectedRangeId,
        onRangeSelect,
    } = props;

    return productRanges.when(
        (data) => {
            return <FormControl>
                <InputLabel id="product-range-selector-label">Range</InputLabel>
                <Select
                    labelId="product-range-selector-label"
                    value={selectedRangeId ?? 'all'}
                    input={<OutlinedInput label="Range" />}
                    onChange={(v: SelectChangeEvent<string>) => {
                        onRangeSelect(v.target.value)
                    }}
                    sx={{
                        width: 250
                    }}
                >
                    <MenuItem
                        key={'all'}
                        value={'all'}
                    >
                        All
                    </MenuItem>
                    {
                        data.map(
                            (item) => <MenuItem
                                key={item._id}
                                value={item._id}
                            >
                                {item.localisedValue}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        },
        (error) => {
            return <Button
                variant="outlined"
                onClick={retry}
            >
                RETRY PRODUCT RANGE LOAD
            </Button>;
        },
        () => {
            return <Typography>Loading product ranges</Typography>
        },
    )
}