import React from "react";
import { TextField } from "@mui/material";
import { Spacer } from "../../../components/atoms/spacer.tsx";
import { CategoryProductsFilter } from "./category_products_filter.tsx";
import { FeatureProductsFilter } from "./feature_products_filter.tsx";
import { ProductRangesProductsFilter } from "./product_range_products_filter.tsx";

type ManagementProductsFiltersProps = {
    selectedCategoryId: string,
    onCategorySelect: (selectedId: string) => void,
    selectedTitle: string,
    onTitleSelect: (value: string) => void,
    selectedFeatureId: string,
    onFeatureIdSelect: (value: string) => void,
    selectedRangeId: string,
    onRangeIdSelect: (value: string) => void,
}

export const ManagementProductsFilters: React.FC<ManagementProductsFiltersProps> = (
    props: ManagementProductsFiltersProps,
) => {
    const {
        selectedCategoryId,
        onCategorySelect,
        selectedTitle,
        onTitleSelect,
        selectedFeatureId,
        onFeatureIdSelect,
        selectedRangeId,
        onRangeIdSelect,
    } = props;

    return <div
        style={{
            display: 'flex',
        }}
    >
        <TextField
            label="Title"
            onChange={(v) => {
                onTitleSelect(v.target.value)
            }}
            type="text"
            value={selectedTitle}
            sx={{
                width: 250
            }}
        />
        <Spacer
            axis="horizontal"
            pixels={8}
        />
        <CategoryProductsFilter
            selectedCategoryId={selectedCategoryId}
            onCategorySelect={onCategorySelect}
        />
        <Spacer
            axis="horizontal"
            pixels={8}
        />
        <FeatureProductsFilter
            selectedFeatureIds={selectedFeatureId}
            onSelect={onFeatureIdSelect}
        />
        <Spacer
            axis="horizontal"
            pixels={8}
        />
        <ProductRangesProductsFilter
            selectedRangeId={selectedRangeId}
            onRangeSelect={onRangeIdSelect}
        />
    </div>
}