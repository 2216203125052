import React, { useRef, useState } from "react";
import { IndexIndicator } from '../atoms/index_indicator.tsx';
import { appColors, appTheme } from "../../app_theme.ts";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useDimensions } from "../../hooks/use_dimensions_hook.tsx";

type CarouselProps = {
    items: React.JSX.Element[],
    iconButtonFillColour?: string,
    iconButtonIconColour?: string,
    indexIndicatorColour?: string,
    indexIndicatorActiveColour?: string,
    positionIndicatorInternally?: boolean,
}

export const Carousel: React.FC<CarouselProps> = (
    props: CarouselProps,
) => {
    const {
        items,
        iconButtonFillColour,
        iconButtonIconColour,
        indexIndicatorColour,
        indexIndicatorActiveColour,
        positionIndicatorInternally,
    } = props;

    const [page, setPage] = useState(0);
    const [scrollOffset, setScrollOffset] = useState(0);
    const startX = useRef(0);
    const objectRef = useRef<HTMLDivElement>(null);

    const { width } = useDimensions(objectRef);

    const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'));

    function handleDrag(value: number) {
        const diff: number = startX.current - value;
        setScrollOffset(diff);
    }

    function handleEnd() {
        if (Math.abs(scrollOffset) > 50) {
            if (scrollOffset > 0) {
                nextPage();
            } else {
                previousPage();
            }
        }
        setScrollOffset(0);
    }

    function nextPage() {
        if (page < items.length - 1) {
            setPage(page + 1)
        }
    }

    function previousPage() {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    return <>
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                width: "100%",
                position: 'relative',
            }}
        >
            <IconButton
                onClick={previousPage}
                sx={{
                    backgroundColor: iconButtonFillColour ?? appColors.primary.light,
                    ":hover": {
                        backgroundColor: iconButtonFillColour ?? appColors.primary.light,
                    },
                    alignSelf: 'center',
                    position: 'absolute',
                    left: {
                        md: '32px',
                        lg: '68px',
                        xl: '68px',
                    },
                    display: isMobile ? 'none' : undefined,
                    zIndex: 999,
                }}
            >
                <ArrowLeft
                    sx={{
                        color: iconButtonIconColour ?? 'white',
                    }}
                />
            </IconButton>
            <Box
                ref={objectRef}
                onTouchStart={(v) => {
                    if (v.targetTouches[0]) {
                        startX.current = v.targetTouches[0].clientX;
                    }
                }}
                onTouchMove={(v) => {
                    if (v.targetTouches[0]) {
                        handleDrag(v.targetTouches[0].clientX);
                    }
                }}
                onTouchEnd={handleEnd}
                style={{
                    position: "relative",
                    padding: 0,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: '100%',
                }}
            >
                {
                    items.map(
                        (el, index) => <div
                            key={"item_" + index}
                            style={{
                                boxSizing: "border-box",
                                minWidth: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                                objectFit: "cover",
                                transition: "all .5s ease",
                                WebkitTransition: "all .5s ease",
                                MozTransition: "all .5s ease",
                                transform: `translateX(${-(page * width) + -scrollOffset}px)`,
                            }}
                        >
                            {el}
                        </div>
                    )
                }
            </Box>
            <IconButton
                onClick={nextPage}
                sx={{
                    backgroundColor: iconButtonFillColour ?? appColors.primary.light,
                    ":hover": {
                        backgroundColor: iconButtonFillColour ?? appColors.primary.light,
                    },
                    alignSelf: 'center',
                    position: 'absolute',
                    right: {
                        md: '32px',
                        lg: '68px',
                        xl: '68px',
                    },
                    display: isMobile ? 'none' : undefined,
                    zIndex: 999,
                }}
            >
                <ArrowRight
                    sx={{
                        color: iconButtonIconColour ?? 'white',
                    }}
                />
            </IconButton>
            <Box
                position='absolute'
                paddingTop={{
                    xs: 4,
                    sm: 4,
                    md: 5,
                    lg: 5,
                    xl: 5,
                }}
                zIndex={999}
                bottom={
                    positionIndicatorInternally
                        ? {
                            xs: 16,
                            sm: 16,
                            md: 20,
                            lg: 20,
                            xl: 20,
                        }
                        : {
                            xs: -28,
                            sm: -28,
                            md: -32,
                            lg: -32,
                            xl: -32,
                        }
                }
            >
                <IndexIndicator
                    current={page}
                    max={items.length}
                    color={indexIndicatorColour ?? appColors.greys.light}
                    focusColor={indexIndicatorActiveColour ?? appColors.greys.medium}
                />
            </Box>
        </div >
        {
            positionIndicatorInternally
                ? <></>
                : <Box
                    height={{
                        xs: "16px",
                        sm: "16px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                    }}
                />
        }
    </>
}