import { DialogContent, DialogActions, DialogTitle, Button, Dialog, Typography } from "@mui/material";
import React from "react";

type DeleteProductFacetModalProps = {
    valueName: string,
    onConfirm: () => void;
    onCancel: () => void,
    visible: boolean,
}

export const DeleteProductFacetModal: React.FC<DeleteProductFacetModalProps> = (
    props: DeleteProductFacetModalProps,
) => {
    const {
        valueName,
        onConfirm,
        onCancel,
        visible,
    } = props;

    return <Dialog
        open={visible}
        onClose={onCancel}
    >
        <DialogTitle>
            Confirm
        </DialogTitle>
        <DialogContent>
            <Typography
                variant="body1"
                align="center"
                sx={{
                    margin: "0px 32px"
                }}
            >
                You are about to delete
            </Typography>
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: "600",
                }}
                align="center"
            >
                {valueName}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onCancel}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button
                onClick={onConfirm}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
}