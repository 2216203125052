import { create } from 'zustand'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'
import { Product } from '../entities/product.ts'

type State = {
    product: AsyncValue<Product>,
}

type Actions = {
    load: (id: string) => Promise<void>,
}

export const productDetailsStore = create<State & Actions>((set, state) => ({
    product: new AsyncLoading<Product>(),
    load: async (id: string) => {
        set(
            () => (
                {
                    product: new AsyncLoading<Product>()
                }
            )
        )
        try {
            const result: Product = await apiClient.getProductById(id);

            set(
                () => (
                    { product: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        product: new AsyncError(e)
                    }
                )
            );
        }
    },
}))