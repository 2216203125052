import React from "react";
import { categoryManagementStore } from "../../../data/states/category_management_store.ts";
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";

type ManagementProductsFilterProps = {
    selectedCategoryId: string,
    onCategorySelect: (selectedId: string) => void,
}

export const CategoryProductsFilter: React.FC<ManagementProductsFilterProps> = (
    props: ManagementProductsFilterProps,
) => {
    const { categories, retry } = categoryManagementStore();

    const {
        selectedCategoryId,
        onCategorySelect,
    } = props;

    return categories.when(
        (data) => {
            return <FormControl>
                <InputLabel id="category-selector-label">Category</InputLabel>
                <Select
                    labelId="category-selector-label"
                    value={selectedCategoryId ?? 'all'}
                    input={<OutlinedInput label="Category" />}
                    onChange={(v: SelectChangeEvent<string>) => {
                        onCategorySelect(v.target.value)
                    }}
                    sx={{
                        width: 250
                    }}
                >
                    <MenuItem
                        key={'all'}
                        value={'all'}
                    >
                        All
                    </MenuItem>
                    {
                        data.map(
                            (item) => <MenuItem
                                key={item._id}
                                value={item._id}
                            >
                                {item.localisedValue}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        },
        (error) => {
            return <Button
                variant="outlined"
                onClick={retry}
            >
                RETRY CATEGORY LOAD
            </Button>;
        },
        () => {
            return <Typography>Loading categories</Typography>
        },
    )
}