import React, { useEffect } from "react";
import { productsByRangeStore } from "../../../data/stores/products_by_range_store.tsx";
import { Box, Button, Grid2, Skeleton, Typography, } from "@mui/material";
import { apiClient } from "../../../data/network/api_client.ts";
import { Carousel } from "../../../components/organisms/carousel.tsx";
import { searchBuilder } from "../../../data/stores/search_builder_store.tsx";
import { useNavigate } from "react-router-dom";
import { appColors } from "../../../app_theme.ts";

const Loader: React.FC = () => {
    return <Skeleton
        sx={{
            width: '100%',
            height: {
                xs: '490px',
                sm: '490px',
                md: '490px',
                lg: '558px',
                xl: '558px',
            }
        }}
    />
}


export const HomeProductsByRange: React.FC = () => {
    const { productsByRange, load } = productsByRangeStore();
    const { setFilter } = searchBuilder();
    const navigate = useNavigate();

    useEffect(
        () => {
            load();
        },
        [load],
    )

    return productsByRange.when(
        (data) => {
            return <Box
                sx={{
                    backgroundColor: "primary.light",
                    maxWidth: '100%'
                }}
                height={{
                    xs: '490px',
                    sm: '490px',
                    md: '490px',
                    lg: '558px',
                    xl: '558px',
                }}
                justifyContent='center'
                alignContent='center'
            >
                <Carousel
                    iconButtonFillColour="white"
                    iconButtonIconColour="primary.light"
                    items={
                        data.map(
                            (el) => {
                                return <Grid2
                                    key={el._id}
                                    container
                                    columns={{
                                        xs: 1,
                                        sm: 1,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                    }}
                                    margin={{
                                        xs: '0px 48px',
                                        sm: '0px 48px',
                                        md: '0px 96px',
                                        lg: '0px 140px',
                                        xl: '0px 140px',
                                    }}
                                >
                                    <Grid2
                                        key={el._id}
                                        size={1}
                                        container
                                        flexDirection='column'
                                        alignContent='start'
                                        justifyContent='center'
                                    >
                                        <Typography
                                            color="primary.main"
                                            lineHeight={1.2}
                                            fontSize={{
                                                xs: 28,
                                                sm: 28,
                                                md: 32,
                                                lg: 36,
                                                xl: 40,
                                            }}
                                        >
                                            {el.localisedValue}
                                        </Typography>
                                        <Typography
                                            color="primary.dark"
                                            marginBottom={{
                                                xs: 3,
                                                sm: 3,
                                                md: 4,
                                                lg: 5,
                                                xl: 5,
                                            }}
                                            lineHeight={1}
                                            fontSize={{
                                                xs: 20,
                                                sm: 20,
                                                md: 26,
                                                lg: 32,
                                                xl: 32,
                                            }}
                                        >
                                            Range
                                        </Typography>
                                        <Box>
                                            <Button
                                                variant='contained'
                                                onClick={() => {
                                                    setFilter({
                                                        range: {
                                                            _id: el._id,
                                                            localisedValue: el.localisedValue,
                                                            canonicalValue: el.canonicalValue,
                                                        },
                                                        clear: true,
                                                    });
                                                    navigate('/search')
                                                }}
                                            >
                                                {`View all ${el.localisedValue}`}
                                            </Button>
                                        </Box>
                                    </Grid2>
                                    <Grid2
                                        container
                                        size={2}
                                        columns={2}
                                        marginTop={{
                                            xs: 6,
                                            sm: 6,
                                            md: 4,
                                            lg: 5,
                                            xl: 5,
                                        }}
                                        spacing={{
                                            xs: 3,
                                            sm: 3,
                                            md: 4,
                                            lg: 5,
                                            xl: 5,
                                        }}
                                    >
                                        {
                                            el.products.slice(0, 2).map(
                                                (product) => {
                                                    return <Grid2
                                                        key={product._id}
                                                        size={1}
                                                        height={{
                                                            xs: "192px",
                                                            sm: "240px",
                                                            md: "340px",
                                                            lg: "340px",
                                                            xl: "340px",
                                                        }}
                                                    >
                                                        <img
                                                            src={apiClient.makeImageUrl(product.images[0].path)}
                                                            alt=""
                                                            height='100%'
                                                            width='100%'
                                                            style={{
                                                                objectFit: 'cover',
                                                                borderRadius: '16px',
                                                            }}
                                                        />
                                                    </Grid2>
                                                }
                                            )
                                        }
                                    </Grid2>
                                </Grid2>
                            }
                        )
                    }
                    indexIndicatorActiveColour={appColors.white}
                    indexIndicatorColour={appColors.greys.accent}
                />
            </Box >
        },
        (error) => <></>,
        () => <Loader />
    );

}