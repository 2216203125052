import { Fab } from "@mui/material";
import React, { PropsWithChildren } from "react";

type FloatingActionButtonProps = PropsWithChildren & {
    onClick: () => void,
};

export const FloatingActionButton: React.FC<FloatingActionButtonProps> = (props: FloatingActionButtonProps) => {
    const {
        onClick,
        children,
    } = props;

    return <div
        style={{
            right: "32px",
            bottom: "32px",
            position: "fixed",
        }}
    >
        <Fab onClick={onClick} color="primary">
            {children}
        </Fab>
    </div>;
}