import React from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index } = props;

    if(value !== index){
        return <></>;
    }

    return children;
};