import { create } from 'zustand'
import { Category } from '../entities/category'
import { apiClient } from '../network/api_client.ts'
import { ProductFeature } from '../entities/product_feature.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'

type State = {
    productFeatures: AsyncValue<ProductFeature[]>,
}

type Actions = {
    getProductFeatures: () => Promise<void>,
    createProductFeature: (
        name: string,
    ) => Promise<void>,
    deleteProductFeature: (
        id: string,
    ) => Promise<void>,
    retry: () => Promise<void>,
}

export const productFeatureManagementStore = create<State & Actions>((set) => ({
    productFeatures: new AsyncLoading<ProductFeature[]>(),
    getProductFeatures: async () => {
        try {
            var result: Category[] = await apiClient.getProductFeatures();

            set(
                () => (
                    { productFeatures: new AsyncData<ProductFeature[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { productFeatures: new AsyncError<ProductFeature[]>(e) }
                )
            );
        }
    },
    createProductFeature: async (name: string) => {
        try {
            const result = await apiClient.createProductFeature(
                name,
            );
            set(
                (state: State) => (
                    { productFeatures: new AsyncData([...(state.productFeatures as AsyncData<ProductFeature[]>).value, result]) }
                )
            );
        } catch (e) {
            alert("Failed to create product feature");
        }
    },
    deleteProductFeature: async (id: string) => {
        try {

            await apiClient.deleteProductFeature(
                id,
            );

            set(
                (state: State) => (
                    { productFeatures: new AsyncData((state.productFeatures as AsyncData<ProductFeature[]>).value.filter(item => item._id !== id)) }
                )
            );
        } catch (e) {
            alert(e);
        }
    },
    retry: async () => {
        set(
            () => (
                { productFeatures: new AsyncLoading() }
            )
        );

        try {
            var result: Category[] = await apiClient.getProductFeatures();

            set(
                () => (
                    { productFeatures: new AsyncData<ProductFeature[]>(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    { productFeatures: new AsyncError<ProductFeature[]>(e) }
                )
            );
        }
    },
}))