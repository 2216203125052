import { create } from 'zustand'
import { Category } from '../entities/category'
import { apiClient } from '../network/api_client.ts'
import { AsyncData, AsyncError, AsyncLoading, AsyncValue } from '../entities/async_value.ts'

type State = {
    categories: AsyncValue<Category[]>,
}

type Actions = {
    getCategories: () => Promise<void>,
    createCategory: (
        name: string,
    ) => Promise<void>,
    deleteCategory: (
        id: string,
    ) => Promise<void>,
    retry: () => Promise<void>,
}

export const categoryManagementStore = create<State & Actions>((set) => ({
    categories: new AsyncLoading<Category[]>(),
    getCategories: async () => {
        try {
            const result: Category[] = await apiClient.getCategories();

            set(
                () => (
                    { categories: new AsyncData(result) }
                )
            );
        } catch (e) {
            set(
                () => (
                    {
                        categories: new AsyncError(e)
                    }
                )
            );
        }
    },
    createCategory: async (
        name: string,
    ) => {
        try {
            const result: Category = await apiClient.createCategory(
                name,
            );

            set(
                (state: State) => (
                    {
                        categories: new AsyncData([...(state.categories as AsyncData<Category[]>).value, result])
                    }
                )
            );
        } catch (e) {
            alert("Failed to create category");
        }
    },
    deleteCategory: async (id: string) => {
        try {
            await apiClient.deleteCategory(
                id,
            );

            set(
                (state: State) => (
                    { categories: new AsyncData((state.categories as AsyncData<Category[]>).value.filter(item => item._id !== id)) }
                )
            );
        } catch (e) {
            alert(e);
        }
    },
    retry: async () => {
        set(
            () => (
                { categories: new AsyncLoading() }
            )
        );

        try {
            const result: Category[] = await apiClient.getCategories();

            const newState: State = {
                categories: new AsyncData(result),
            }

            set(
                (state: State) => (
                    newState
                )
            );
        } catch (e) {
            set(
                () => (
                    { categories: new AsyncError(e) }
                )
            );
        }
    },
}))